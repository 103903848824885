<template>
	<div>
		<div v-click-outside="onClose" class="dropdown-wrapper">
			<div @click="expanded = ! expanded"
				 class="button">
				<template v-if="!! view">
					<i :class="view.icon" />
					<span>{{ view.label }}</span>
				</template>

				<i class="far fa-chevron-down fa-fw" />
			</div>

			<div v-if="expanded"
				 class="views">
				<div v-for="key in Object.keys(views)"
					 :key="`view-${key}`"
					 @click="onChange(key)"
					 class="view">
					<i :class="views[key].icon" />
					<span>{{ views[key].label }}</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import ClickOutside from 'vue-click-outside'

import datepickerMixin from '@/app/datepicker/datepicker.mixin'
import FacebookService from "@/services/_app/meta/FacebookService";

export default {
	directives: {
		ClickOutside
	},

	mixins: [
		datepickerMixin
	],

	props: {
		value: {},

		viewRequirements: {
			type: Object,
			required: true
		}
	},

	data: () => ({
		expanded: false,
		values: {},
	}),

	computed: {
		views() {
			const views = {
				default: {
					icon: 'far fa-list fa-fw',
					label: this.$t('meta.ads.views.default')
				},

				efficiency: {
					icon: 'far fa-mouse-pointer fa-fw',
					label: this.$t('meta.ads.views.efficiency')
				},

				branding: {
					icon: 'far fa-certificate fa-fw',
					label: this.$t('meta.ads.views.branding')
				},

				video: {
					icon: 'far fa-video fa-fw',
					label: this.$t('meta.ads.views.video')
				},

				ecommerce: {
					icon: 'far fa-shopping-cart fa-fw',
					label: this.$t('meta.ads.views.ecommerce')
				},

				leads: {
					icon: 'far fa-filter fa-fw',
					label: this.$t('meta.ads.views.leads')
				},

				contact: {
					icon: 'far fa-envelope fa-fw',
					label: this.$t('meta.ads.views.contact')
				},

				registrations: {
					icon: 'far fa-user-plus fa-fw',
					label: this.$t('meta.ads.views.registrations')
				}
			}

			const requirements = this.viewRequirements

			return Object.keys(views).reduce(
				(carry, key) => {
					let value = null

					if (!! requirements[key] && this.value !== key) {
						if (requirements[key].subtype === 'action') {

							const actions = this.values.actions || []
							const match = actions.find(action => action.action_type === requirements[key].field)

							value = !! match ? match.value : null
						} else if (requirements[key].subtype === 'conversions') {
							const conversions = this.values.conversions || []
							const match = conversions.find(conversion => conversion.action_type === requirements[key].field)

							value = !! match ? match.value : null
						} else {
							if (typeof this.values[requirements[key].field] === 'object') {
								value = Array.isArray(this.values[requirements[key].field])
									? this.values[requirements[key].field][0].value
									: this.values[requirements[key].field].value
							} else {
								value = this.values[requirements[key].field]
							}
						}

						if (! value) {
							return carry
						}
					}

					return {
						...carry,
						[key]: views[key]
					}
				},
				{}
			)
		},

		view() {
			return this.views[this.internalValue]
		},

		internalValue: {
			get() {
				return this.value
			},

			set(value) {
				this.$emit('input', value)
			}
		}
	},

	watch: {
		from() {
			this.onLoad()
		}
	},

	mounted() {
		this.onLoad()
	},

	methods: {
		onLoad() {
			return new Promise((resolve) => {
				FacebookService.accountInsightsTotal({
					from: this.from.format('YYYY-MM-DD'),
					to: this.to.format('YYYY-MM-DD'),

					fields: [
						'action_values',
						'outbound_clicks',
						'outbound_clicks_ctr',
						'inline_link_clicks',
						'inline_link_click_ctr',
						'cost_per_inline_link_click',
						'cost_per_outbound_click',
						'clicks',
						'reach',
						'impressions',
						'spend',
						'website_purchase_roas',
						'frequency',
						'cpm',
						'video_play_actions',
						'video_p100_watched_actions',
						'cost_per_thruplay',
						'action_values',
						'actions',
						'account_currency',
						'conversions',
						'cost_per_conversion',
						'cost_per_action_type'
					]
				}).then(response => {
					this.values = response.insights[0] || {}

					resolve()
				})
			})
		},

		onChange(value) {
			this.internalValue = value
			this.onClose()
		},

		onClose() {
			this.expanded = false
		}
	}
}
</script>

<style lang="scss" scoped>
.dropdown-wrapper {
	position: relative;

	> div.button {
		cursor: pointer;
		user-select: none;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		gap: 10px;
		padding: 0 10px;
		color: #000;
		font-size: 14px;
		font-weight: 500;
		border: 1px solid #ccc;
		border-radius: 6px;
		height: 36px;
	}

	> div.views {
		position: absolute;
		top: 100%;
		right: 0;
		min-width: 200px;
		border: 1px solid #ccc;
		border-radius: 6px;
		background-color: #fff;
		margin-top: 10px;
		z-index: 99;

		> div.view {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			gap: 10px;
			color: #000;
			font-size: 14px;
			font-weight: 400;
			padding: 10px 15px;
			border-bottom: 1px solid #eee;

			&:hover {
				cursor: pointer;
				user-select: none;
				background-color: #eee;
			}
		}
	}
}
</style>
