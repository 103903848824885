<template>
    <section class="row flex-parent" v-if="hasMedia">
        <view-post
            v-if="currentPost"
            :post="currentPost"
            :token="fbAccessToken"
            class="col-lg-4 col-md-5 col-sm-12 col-xs-12 flex-child--full post-between-lg-md"
        />
        <div
            class="col-lg-8 col-md-7 col-md-12 col-sm-12 col-xs-12 post-container-between-lg-md"
        >
            <card
                icon="images"
                :headline="$t('social.overview.headline')"
                :description="$t('social.overview.description')"
            >
                <div class="posts-container" v-if="hasPosts">
                    <div
                        class="post-quick-view"
                        v-for="post in posts"
                        :class="{
                            'selected-post': post && post.id === currentPost.id
                        }"
                        @click="setCurrentPost(post)"
                    >
                        <figure>
                            <img
                                :src="
                                    post.media_type === 'VIDEO'
                                        ? post.thumbnail_url
                                        : post.media_url
                                "
                            />
                        </figure>

                        <p class="caption">
                            {{ post.caption | substr(0, 50) }}...
                        </p>
                    </div>
                </div>

                <infinite-loading
                    @infinite="loadNextPage($event)"
                    v-if="hasPosts"
                    :distance="250"
                >
                    <span slot="no-results"></span>
                    <span slot="no-more"></span>
                </infinite-loading>
            </card>
        </div>
    </section>
</template>

<script>
import ViewPost from "./ViewPost";
import InfiniteLoading from "vue-infinite-loading";
import { mapGetters, mapActions } from "vuex";

export default {
    name: "MediaPosts",
    components: { ViewPost, InfiniteLoading },
    data() {
        return {
            currentPost: null,
            fbAccessToken: "",
            pagination: {},
            posts: []
        };
    },
    mounted() {
        if (this.hasMedia) {
            this.pagination = this.media.paging;

            this.fbAccessToken = new URL(this.pagination.next).searchParams.get(
                "access_token"
            );
            this.posts = this.media.data;
            this.currentPost = this.posts[0];
        }
    },
    computed: {
        ...mapGetters("instagram", {
            media: "getMedia"
        }),
        hasMedia() {
            if (!this.media.hasOwnProperty("data")) {
                return false;
            }
            return this.media.data.length > 0;
        },
        hasPosts() {
            return this.posts.length > 0;
        }
    },
    methods: {
        ...mapActions("instagram", {
            fetch: "fetchMorePosts"
        }),
        setCurrentPost(post) {
            this.currentPost = post;
        },
        async loadNextPage($state) {
			$state.complete();
			return
            const response = await this.fetch(this.pagination.next);

            this.pagination = response.paging;

            if (response.data.length <= 0) {
                $state.complete();
                return;
            }

            this.posts = [...this.posts, ...response.data];

            $state.loaded();
        }
    }
};
</script>

<style scoped lang="scss">
figure {
    transition: all ease-in-out 400ms;
    border-radius: 1px;
    display: flex;
    width: 100%;
    position: relative;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    z-index: 10;

    &:before {
        content: "";
        padding-top: 100%;
        width: 100%;
        display: block;
    }

    img {
        min-width: 100%;
        max-height: 100%;
        position: absolute;
    }
}

.posts-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 30px 30px;
    @media screen and (min-width: 1024px) {
        grid-template-columns: 1fr 1fr;
    }

    @media screen and (min-width: 1024px) {
        grid-template-columns: 1fr 1fr;
    }

    @media screen and (min-width: 1200px) {
        grid-template-columns: 1fr 1fr 1fr;
    }

    @media screen and (min-width: 1300px) {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    p.caption {
        margin: 10px 0 0;
        position: relative;
        z-index: 10;
        font-size: 10px;
        color: #888888;
    }
}

.post-quick-view {
    position: relative;
    border-radius: 1px;
    cursor: pointer;

    &::before {
        transition: all ease-in-out 200ms;
        z-index: 5;
        content: "";
        display: block;
        position: absolute;
        left: -10px;
        top: -10px;
        right: -10px;
        bottom: -10px;
        background: transparent;
        border: 1px solid transparent;
    }

    &:hover,
    &.selected-post {
        figure {
            transform: scale(0.97);
        }
        &::before {
            background: #f9f9f9;
            border: 1px solid #f0f0f0;
        }
    }
}
</style>
