<template>
	<div class="networks"
		 ref="wrapper">
		<button v-if="canGoPrevious"
				@click="previous"
				type="button"
				class="paginate left">
			<i class="fas fa-chevron-left fa-fw" />
		</button>

		<button v-if="canGoNext"
				@click="next"
				type="button"
				class="paginate right">
			<i class="fas fa-chevron-right fa-fw" />
		</button>


		<div class="list"
			 :class="[! scrolling ? 'scroll-snap' : undefined]"
			 ref="carousel"
			 @scroll="onScroll">
			<div v-for="slug in Object.keys(networks)"
				 class="network">
				<div class="title">
					<i :class="networks[slug].icon" />
					<span>{{ networks[slug].label }}</span>
				</div>

				<div class="figures">
					<div v-for="metric in metrics"
						 :key="`metric-${metric.label}`"
						 class="figure">
						<div class="meta">
							<div class="type">
								<i class="fa-fw icon" :class="metric.icon" />
								<span>{{ metric.label }}</span>
							</div>

							<div v-if="formatValue(slug, metric.field, metric.type, metric.subtype, metric) !== null"
								 class="value">
								{{ formatValue(slug, metric.field, metric.type, metric.subtype, metric) }}
							</div>

							<div v-else-if="! loaded" class="mock value">
								12345
							</div>

							<div v-else class="value">
								-
							</div>

							<template v-if="showComparison">
								<div v-if="diff(slug, metric.field, metric.subtype) !== null"
									 class="change">
									<i v-if="diff(slug, metric.field, metric.subtype).toFixed() > 0"
									   class="fas fa-caret-up fa-fw"
									/>

									<i v-else-if="diff(slug, metric.field, metric.subtype).toFixed() < 0"
									   class="fas fa-caret-down fa-fw"
									/>

									<span>{{ Math.abs(diff(slug, metric.field, metric.subtype)).toFixed() }} %</span>
								</div>

								<div v-else-if="! loaded" class="change mock">
									<i class="fas fa-caret-up fa-fw" />
									<span>12%</span>
								</div>

								<div v-else class="change">
									<span>-</span>
								</div>
							</template>
						</div>
					</div>
				</div>

				<div v-if="slug === 'meta'"
					 v-b-tooltip.hover
					 :title="$t('google.ads.aggregated.tooltip')"
					 class="aggregated">
					<i class="fas fa-info-circle fa-fw" />
					<span>{{ $t('google.ads.aggregated.label') }}</span>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
div.networks {
	position: relative;

	> button.paginate {
		position: absolute;
		top: 50%;

		width: 40px;
		height: 40px;
		border-radius: 50%;

		display: flex;
		align-items: center;
		justify-content: center;

		font-size: 16px;

		box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

		border: 0;
		background-color: #fff;

		&.left {
			left: 0;
			transform: translateY(-50%) translateX(-50%);
		}

		&.right {
			right: 0;
			transform: translateY(-50%) translateX(50%);
		}
	}

	> div.list {
		display: flex;
		overflow-x: scroll;

		&::-webkit-scrollbar {
			display: none;
		}

		-ms-overflow-style: none;
		scrollbar-width: none;


		&.scroll-snap {
			scroll-snap-type: x mandatory;
			scroll-behavior: smooth;

			> div.network {
				scroll-snap-align: start;
				scroll-snap-stop: always;
			}
		}

		gap: 15px;

		> div.network {
			flex-basis: calc(33.3333% - 10px);
			flex-shrink: 0;

			> div.aggregated {
				display: flex;
				align-items: center;
				justify-content: center;
				gap: 8px;
				padding: 15px 10px;
				border-top: 1px solid #eee;

				> i {
					font-size: 11px;
				}

				> span {
					color: #000;
					font-size: 15px;
					font-weight: 500;
				}
			}

			> div.title {
				display: flex;
				align-items: center;
				justify-content: center;
				gap: 10px;
				padding: 20px;

				i {
					color: #274fd2;
					font-size: 26px;
				}

				span {
					color: #000;
					font-size: 20px;
					font-weight: 600;
				}
			}

			border: 1px solid #eee;
			border-radius: 8px;
			background-color: #fff;

			> div.figures {
				display: flex;
				flex-wrap: wrap;

				> div.figure {
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					gap: 10px;

					padding: 15px;

					flex-basis: 50%;
					flex-shrink: 0;
					flex-grow: 0;
					border-top: 1px solid #eee;

					> div.icon {
						color: #274fd2;
						font-size: 20px;
					}

					> div.meta {
						display: flex;
						flex-direction: column;
						justify-content: center;
						align-items: center;
						line-height: 100%;
						gap: 15px;

						> div.type {
							display: flex;
							flex-direction: column;
							align-items: center;
							justify-content: center;
							gap: 6px;
							color: #79828d;
							font-weight: 500;
							font-size: 14px;
							letter-spacing: -.1px;

							> .icon {
								color: #274fd2;
								font-size: 125%;
							}

							i {
								font-size: 80%;
							}
						}

						> div.value {
							color: #262626;
							font-size: 18px;
							font-weight: 600;
						}

						> div.change {
							display: flex;
							align-items: center;
							gap: 2px;
							color: #727272;
							font-size: 13px;
							font-weight: 400;
						}
					}
				}
			}
		}
	}
}

@media screen and (max-width: 1500px) {
	div.networks {
		> div.list {
			> div.network {
				flex-basis: calc(50% - 7.5px);
			}
		}
	}
}

@media screen and (max-width: 945px) {
	div.networks {
		> div.list {
			> div.network {
				flex-basis: 100%;
			}
		}
	}
}
</style>

<script>
import FacebookService from '@/services/_app/meta/FacebookService'
import datepickerMixin from '@/app/datepicker/datepicker.mixin'
import marketingMixin from '@/mixins/marketingMixin'

import moment from 'moment'
import {mapGetters} from "vuex";

export default {
	mixins: [
		datepickerMixin,
		marketingMixin
	],

	props: {
		networks: {
			type: Object,
			required: true
		},

		views: {
			type: Object,
			required: true
		},

		view: {
			type: String,
			required: true
		}
	},

	data: () => ({
		networkData: {},
		networkDataPrevious: {},
		loaded: false,

		offset: 0,
		scrolling: false,
		iterator: 0
	}),

	computed: {
		showComparison() {
			return this.$store.getters['datepicker/getShowComparison']
		},

		daysBetween() {
			const duration = moment.duration(this.to.diff(this.from))
			return duration.asDays()
		},

		previousFrom() {
			return moment(this.from).subtract(1, 'year')
		},

		previousTo() {
			return moment(this.to).subtract(1, 'year')
		},

		metrics() {
			return this.views[this.view]
		},

		canGoPrevious() {
			return this.offset > 20
		},

		canGoNext() {
			const iterator = this.iterator
			const wrapper = this.$refs.wrapper

			if (! wrapper) {
				return
			}

			return this.offset < this.elmScrollWidth() - wrapper.offsetWidth - 100
		}
	},

	watch: {
		from() {
			this.networkData = {}
			this.networkDataPrevious = {}

			this.onLoad()
		}
	},

	mounted() {
		this.onLoad()

		window.addEventListener('resize', this.iterate)

		this.$nextTick(() => {
			this.iterate()
		})
	},

	beforeDestroy() {
		window.removeEventListener('resize', this.iterate)
	},

	methods: {
		formatValue(network, field, type, subtype, view) {
			if (! this.networkData[network]) {
				return null
			}

			let value = null

			if (subtype === 'calculation') {
				value = !! view.calculation
					? view.calculation(JSON.parse(JSON.stringify(this.networkData[network])))
					: null
			} else if (subtype === 'action') {
				const actions = this.networkData[network].actions || []
				const match = actions.find(action => action.action_type === field)

				value = !! match ? match.value : null
			} else if (subtype === 'action_value') {
				const actions = this.networkData[network].action_values || []
				const match = actions.find(action => action.action_type === field)

				value = !! match ? match.value : null
			} else if (subtype === 'conversions') {
				const conversions = this.networkData[network].conversions || []
				const match = conversions.find(conversion => conversion.action_type === field)

				value = !! match ? match.value : null
			} else if (subtype === 'cost_per_conversion') {
				const conversions = this.networkData[network].cost_per_conversion || []
				const match = conversions.find(conversion => conversion.action_type === field)

				value = !! match ? match.value : null
			} else if (subtype === 'cost_per_action_type') {
				const conversions = this.networkData[network].cost_per_action_type || []
				const match = conversions.find(conversion => conversion.action_type === field)

				value = !! match ? match.value : null
			} else {
				if (typeof this.networkData[network][field] === 'object') {
					value = Array.isArray(this.networkData[network][field])
						? this.networkData[network][field][0].value
						: this.networkData[network][field].value
				} else {
					value = this.networkData[network][field]
				}
			}

			if (! value) {
				return null
			}

			switch (type) {
				case 'currency':
					return this.marketingFormat(
						Number(value),
						type,
						{ currency: this.networkData[network].account_currency }
					)

				default:
					return this.marketingFormat(
						value,
						type
					)
			}
		},

		diff(network, field, subtype) {
			if (! this.networkData[network] ||
				! this.networkDataPrevious[network]
			) {
				return null
			}

			let value = null
			let valuePrevious = null

			if (subtype === 'action') {
				const actions = this.networkData[network].actions || []
				const match = actions.find(action => action.action_type === field)

				value = !! match ? match.value : null

				const actionsPrevious = this.networkDataPrevious[network].actions || []
				const matchPrevious = actionsPrevious.find(action => action.action_type === field)

				valuePrevious = !! matchPrevious ? matchPrevious.value : null
			} else if (subtype === 'action_value') {
				const actions = this.networkData[network].action_values || []
				const match = actions.find(action => action.action_type === field)

				value = !! match ? match.value : null

				const actionsPrevious = this.networkDataPrevious[network].action_values || []
				const matchPrevious = actionsPrevious.find(action => action.action_type === field)

				valuePrevious = !! matchPrevious ? matchPrevious.value : null
			} else if (subtype === 'conversions') {
				const conversions = this.networkDataPrevious[network].conversions || []
				const match = conversions.find(conversion => conversion.action_type === field)

				value = !! match ? match.value : null
			} else if (subtype === 'cost_per_conversion') {
				const conversions = this.networkDataPrevious[network].cost_per_conversion || []
				const match = conversions.find(conversion => conversion.action_type === field)

				value = !! match ? match.value : null
			} else if (subtype === 'cost_per_action_type') {
				const conversions = this.networkDataPrevious[network].cost_per_action_type || []
				const match = conversions.find(conversion => conversion.action_type === field)

				value = !! match ? match.value : null
			} else {
				if (typeof this.networkData[network][field] === 'object') {
					value = Array.isArray(this.networkData[network][field])
						? this.networkData[network][field][0].value
						: this.networkData[network][field].value
				} else {
					value = this.networkData[network][field]
				}

				if (typeof this.networkDataPrevious[network][field] === 'object') {
					valuePrevious = Array.isArray(this.networkDataPrevious[network][field])
						? this.networkDataPrevious[network][field][0].value
						: this.networkDataPrevious[network][field].value
				} else {
					valuePrevious = this.networkDataPrevious[network][field]
				}
			}

			if (! valuePrevious) {
				return null
			}

			const diff = Number(value) - Number(valuePrevious)

			return diff / Number(value) * 100
		},

		onLoad() {
			this.loaded = false

			const promises = []

			promises.push(
				new Promise((resolve) => {
					FacebookService.accountInsightsTotal({
						from: this.from.format('YYYY-MM-DD'),
						to: this.to.format('YYYY-MM-DD'),

						fields: [
							'action_values',
							'outbound_clicks',
							'outbound_clicks_ctr',
							'inline_link_clicks',
							'inline_link_click_ctr',
							'cost_per_inline_link_click',
							'cost_per_outbound_click',
							'clicks',
							'reach',
							'impressions',
							'spend',
							'website_purchase_roas',
							'frequency',
							'cpm',
							'video_play_actions',
							'video_p100_watched_actions',
							'cost_per_thruplay',
							'action_values',
							'actions',
							'account_currency',
							'conversions',
							'cost_per_conversion',
							'cost_per_action_type'
						]
					}).then(response => {
						this.$set(this.networkData, 'meta', response.insights[0] || {})
						resolve()
					})
				})
			)

			promises.push(
				new Promise((resolve) => {
					FacebookService.accountInsightsTotal({
						from: this.previousFrom.format('YYYY-MM-DD'),
						to: this.previousTo.format('YYYY-MM-DD'),

						fields: [
							'action_values',
							'outbound_clicks',
							'outbound_clicks_ctr',
							'inline_link_clicks',
							'inline_link_click_ctr',
							'cost_per_inline_link_click',
							'cost_per_outbound_click',
							'clicks',
							'reach',
							'impressions',
							'spend',
							'website_purchase_roas',
							'frequency',
							'cpm',
							'video_play_actions',
							'video_p100_watched_actions',
							'cost_per_thruplay',
							'action_values',
							'actions',
							'account_currency',
							'conversions',
							'cost_per_conversion',
							'cost_per_action_type'
						]
					}).then(response => {
						this.$set(this.networkDataPrevious, 'meta', response.insights[0] || {})
						resolve()
					})
				})
			)

			promises.push(
				new Promise((resolve) => {
					FacebookService.accountInsights({
						from: this.from.format('YYYY-MM-DD'),
						to: this.to.format('YYYY-MM-DD'),

						fields: [
							'action_values',
							'outbound_clicks',
							'outbound_clicks_ctr',
							'inline_link_clicks',
							'inline_link_click_ctr',
							'cost_per_inline_link_click',
							'cost_per_outbound_click',
							'clicks',
							'reach',
							'impressions',
							'spend',
							'website_purchase_roas',
							'frequency',
							'cpm',
							'video_play_actions',
							'video_p100_watched_actions',
							'cost_per_thruplay',
							'action_values',
							'actions',
							'account_currency',
							'conversions',
							'cost_per_conversion',
							'cost_per_action_type'
						]
					}).then(response => {
						response.insights.forEach(item => {
							this.$set(this.networkData, item.publisher_platform, item)
						})

						resolve()
					})
				})
			)

			promises.push(
				new Promise((resolve) => {
					FacebookService.accountInsights({
						from: this.previousFrom.format('YYYY-MM-DD'),
						to: this.previousTo.format('YYYY-MM-DD'),

						fields: [
							'action_values',
							'outbound_clicks',
							'outbound_clicks_ctr',
							'inline_link_clicks',
							'inline_link_click_ctr',
							'cost_per_inline_link_click',
							'cost_per_outbound_click',
							'clicks',
							'reach',
							'impressions',
							'spend',
							'website_purchase_roas',
							'frequency',
							'cpm',
							'video_play_actions',
							'video_p100_watched_actions',
							'cost_per_thruplay',
							'action_values',
							'actions',
							'account_currency',
							'conversions',
							'cost_per_conversion',
							'cost_per_action_type'
						]
					}).then(response => {
						response.insights.forEach(item => {
							this.$set(this.networkDataPrevious, item.publisher_platform, item)
						})

						resolve()
					})
				})
			)

			Promise.all(promises).finally(() => {
				this.loaded = true
			})
		},
		elmWidth() {
			const carousel = this.$refs.carousel

			if (! carousel) {
				return null
			}

			return this.elmScrollWidth() / carousel.children.length
		},

		elmScrollWidth() {
			const carousel = this.$refs.carousel

			if (! carousel) {
				return null
			}

			return carousel.scrollWidth
		},

		visibleElements() {
			const wrapper = this.$refs.wrapper

			if (! wrapper) {
				return null
			}

			return Math.ceil(wrapper.offsetWidth / this.elmWidth())
		},

		scrollTo(offset) {
			this.offset = offset

			const carousel = this.$refs.carousel

			if (! carousel) {
				return null
			}

			this.scrolling = true

			carousel.scrollTo({
				left: this.offset,
				behavior: 'smooth'
			})
		},

		next() {
			if (this.scrolling) {
				return
			}

			const elmScrollWidth = this.elmScrollWidth()
			const elmWidth = this.elmWidth()

			if (! elmScrollWidth || ! elmWidth) {
				return
			}

			const scrollWidth = ! this.group
				? elmWidth
				: elmWidth * this.visibleElements()

			this.offset + scrollWidth <= elmScrollWidth - elmWidth
				? this.scrollTo(this.offset + scrollWidth)
				: this.scrollTo(elmScrollWidth - scrollWidth)
		},

		previous() {
			if (this.scrolling) {
				return
			}

			const elmWidth = this.elmWidth()

			if (! elmWidth) {
				return
			}

			const scrollWidth = ! this.group
				? elmWidth
				: elmWidth * this.visibleElements()

			this.offset - scrollWidth > 0
				? this.scrollTo(this.offset - scrollWidth)
				: this.scrollTo(0)
		},

		onScroll($event) {
			const offset = $event.target.scrollLeft

			if (this.scrolling) {
				if (this.offset - 2 < offset &&
					this.offset + 2 > offset
				) {
					this.scrolling = false
				}

				return
			}

			this.offset = offset

			this.iterate()
		},

		iterate() {
			this.iterator++
		}
	}
}
</script>
