<template>
    <article>
        <slot/>
        <logo-loader v-if="isLoading"/>
        <div class="ages-container" v-else>
            <highcharts :options="options"/>
        </div>
    </article>
</template>

<style scoped lang="scss">
    .ages-container {
        margin-top: 20px;
    }
</style>

<script>

  export default {
    props: {
      isLoading: {
        type: Boolean,
        required: true
      },
      ages: {
        type: Array,
        required: true
      },
      total: {
        type: Number,
        required: true
      }
    },

    computed: {
      sortedAges () {
        return this.ages.sort((a, b) => {
          return a.ageRange > b.ageRange ? 1 : -1
        })
      },

      categories () {
        return this.sortedAges.map(row => row.ageRange)
      },

      series () {
        return [
          {
            name: $t('visitors.audience.numberOfVisits'),
            color: $org('colors.standard.secondary.hex'),
            data: this.sortedAges.map(row => row.value)
          }
        ]
      },

      options () {
        return {
          chart: {
            type: 'bar',
            height: 230
          },
          title: false,
          xAxis: {
            categories: this.categories
          },
          yAxis: {
            min: 0,
            title: {
              text: false,
              align: 'high'
            },
            labels: false,
            gridLineWidth: 0
          },
          plotOptions: {
            bar: {
              dataLabels: {
                enabled: false
              }
            }
          },
          legend: false,
          credits: {
            enabled: false
          },
          series: this.series
        }
      }
    },
    methods: {
      init () {
        if (this.isLoading || this.categories.length === 0) {
          return
        }

        if (this.chart) {
          this.chart.destroy()
        }

        setTimeout(this.load, 100)
      }
    }
  }
</script>
