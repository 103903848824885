<template>
    <div class="facebook-social">
        <div class="container-fluid">
            <check-for-missing-integrations :integrations="['Facebook pages']">
                <div slot="no-integrations">
                    <landing-page image="/images/integrations/cta-messages/facebook-social.png"
                                  :headline="$t('social.noIntegration.headline')"
                                  :description="$t('social.noIntegration.description')">
                        <hr>

                        <integration name="Facebook pages"
                                    service="facebook-pages"
                                    :border="true"
                                     :logos="['/images/onboarding/facebook.svg','/images/onboarding/instagram.svg']"
                                     :headline="$t('social.onboarding.headline')"
                                     :description="$t('social.onboarding.description')" />
                    </landing-page>
                </div>

                <check-for-missing-scopes v-if="scopes !== null"
                                            integration="Facebook pages"
                                            :requiredScopes="['pages_show_list', 'read_insights']"
                                            :scopes="scopes">
                    <div slot="no-scopes">
                        <landing-page image="/images/integrations/cta-messages/facebook-social.png"
                                      :headline="$t('social.noIntegration.headline')"
                                      :description="$t('social.noIntegration.description')">
                            <hr>

                            <re-authenticatable-integration name="Facebook pages"
                                                            service="facebook-pages"
                                                            :border="true"
                                                            logo="/images/onboarding/facebook.svg"
                                         :headline="$t('social.onboarding.headline')"
                                         :description="$t('social.onboarding.description')" />
                        </landing-page>
                    </div>

                    <div class="row flex-parent">
                        <div class="col-lg-8 col-between-lg-md col-md-12 col-sm-12 col-xs-12 flex-child--full">
                            <card icon="pe-7s-graph3"
                                :headline="$t('social.historical.headline')"
                                :description="$t('social.historical.description')">
                                <div class="chart-container">
                                    <page-line-chart />
                                </div>
                            </card>
                        </div>

                        <div class="col-lg-4 col-between-lg-md col-md-12 col-sm-12 col-xs-12">
                            <card icon="pe-7s-note"
                                :headline="$t('social.profile.headline')"
                                :description="$t('social.profile.description')">
                                <template v-if="profile">
                                    <div class="profileSubject">
                                        <strong>{{$t('social.followers.title')}}</strong>
                                    </div>
                                    <div class="profileContent" v-html="$t('social.followers.text', {value: profile.fan_count})" />

                                    <br />
                                    <br />

                                    <template v-if="profile.overall_star_rating !== 0">
                                        <div class="profileSubject">
                                            <strong>{{$t('social.rating.title')}}</strong>
                                        </div>

                                        <div class="profileContent" v-html="$t('social.rating.text', {value: profile.overall_star_rating})" />

                                        <br />
                                        <br />
                                    </template>

                                    <div class="profileSubject">
                                        <strong>{{$t('social.talkingAbout.title')}}</strong>
                                    </div>
                                    <div class="profileContent" v-html="$t('social.talkingAbout.text', {value: profile.talking_about_count})" />
                                </template>

                                <div class="text-center" v-else>
                                    <span class="fa fa-circle-o-notch fa-fw fa-spin" />
                                </div>

                                <div class="clearfix" />
                            </card>

                            <div class="clearfix" />

                            <card v-if="1 === 2"
								  icon="pe-7s-users"
                                  :headline="$t('social.demographics.headline')"
                                  :description="$t('social.demographics.description')">
                                <template v-if="demographics">
                                    <div v-if="Object.values(demographics).length" class="progress" style="height: 30px; margin-bottom: 0;">
                                        <div class="progress-bar progress-bar-primary"
                                            :style="{width: (demographics.male / demographicsTotal) * 100 + '%'}"
                                            style="padding-top: 6px;">
                                            <span>{{$t('social.demographics.men')}}</span>
                                        </div>

                                        <div class="progress-bar progress-bar-danger"
                                            :style="{width: (demographics.female / demographicsTotal) * 100 + '%'}"
                                            style="padding-top: 6px;">
                                            <span>{{$t('social.demographics.women')}}</span>
                                        </div>
                                    </div>

                                    <p v-else>
                                        <i class="fa fa-info-circle" style="margin-right: 5px;" />
                                        {{$t('social.demographics.noInformation')}}
                                    </p>
                                </template>

                                <div class="text-center" v-else>
                                    <span class="fa fa-circle-o-notch fa-fw fa-spin"/>
                                </div>

                                <div class="clearfix" />
                            </card>
                        </div>
                    </div>

                    <div class="row flex-parent">
                        <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12 flex-child--full post-between-lg-md">
                            <div style="width: 100%;">
                                <card ref="post"
                                    style="width: 100%; position: sticky; top: 75px;"
                                    class="facebook-post"
                                    icon="pe-7s-news-paper"
                                    :headline="$t('social.specificPost.headline')"
                                    :description="$t('social.specificPost.description')">
                                    <facebook-post v-if="currentPost" :post="currentPost"/>

                                    <div class="text-center" v-else>
                                        <span class="fa fa-circle-o-notch fa-fw fa-spin" />
                                    </div>
                                </card>
                            </div>
                        </div>

                        <div class="col-lg-8 col-md-6 col-md-12 col-sm-12 col-xs-12 post-container-between-lg-md">
                            <card icon="pe-7s-albums" :headline="$t('social.overview.headline')" :description="$t('social.overview.description')">
                                <div class="row">
                                    <template v-if="hasPosts">
                                        <div v-for="post in posts"
                                            class="col-lg-3 col-md-6 col-sm-4 col-xs-6 posts-between-lg-md post-quickview"
                                            :class="{ 'selected-post': post && post.id === currentPost.id }"
                                            @click="setCurrentPost(post)">

                                            <div class="image-container" :style="{ 'background-image': 'url(' + post.full_picture + ')'}"></div>

                                            <small>{{post.message | substr(0, 100)}} ...</small>
                                        </div>
                                    </template>

                                    <infinite-loading @infinite="loadNextPage($event)" v-if="hasPosts" :distance="250">
                                        <span slot="no-results"></span>
                                        <span slot="no-more"></span>
                                    </infinite-loading>
                                </div>
                            </card>
                        </div>
                    </div>
                </check-for-missing-scopes>
            </check-for-missing-integrations>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    @import '~@/assets/scss/_mixins.scss';

    @media screen and (min-width: 1200px) and (max-width: 1500px) {
        .post-between-lg-md, .posts-between-lg-md, .post-container-between-lg-md {
            width: 50% !important;
        }
    }

    @media screen and (min-width: 992px) and (max-width: 1100px) {
        .post-between-lg-md, .post-container-between-lg-md {
            width: 100% !important;
        }

        .posts-between-lg-md {
            width: 25% !important;
        }
    }

    .chart-container {
        overflow: hidden;
        height: 380px;
    }

    .profileSubject {
        padding: 0 15px 5px 15px;
        float: left;
        width: 140px;
    }

    .profileContent {
        width: calc(100% - 140px);
        padding: 0 15px 5px 15px;
        float: left;
    }

    .post-placeholder {
        height: 237px;
        padding: 20px;
        .post-placeholder__img {
            width: 100%;
            height: 116px;
            background: #f9f9f9;
        }
        .post-placeholder__text {
            width: 100%;
            height: 55px;
            border: 1px solid #f9f9f9;
            .text {
                height: 5px;
                background: #f9f9f9;
                margin: 10px 0 0 10px;
            }
        }
    }

    .flex-parent {
        display: flex;
        flex-flow: row wrap;
    }

    .flex-child--half {
        display: flex;
        flex: 1;
    }

    .flex-child--full {
        display: flex;
    }

    .post-quickview {
        overflow: hidden;
        padding: 20px;
        height: 200px;
        line-height: 14px;
        transition: all 0.6s ease-out;
        &:not(.selected-post) {
            background: #fefefe;
            border: 1px solid #fafafa;
            cursor: pointer;
        }
        .image-container {
            width: 100%;
            margin-bottom: 5px;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center center;
            &:before {
                content: '';
                display: block;
                padding-top: 56.25%;
            }
        }
        small {
            color: #888;
            font-size: 10px;
        }
    }

    .selected-post {
        background: #f9f9f9;
        border: 1px solid #f0f0f0;
    }

    @media screen and (max-width: 1670px) and (min-width: 1200px) {
        .col-between-lg-md {
            width: 50%;
        }
        .profileSubject {
            width: 100%;
        }
        .profileContent {
            width: 100%;
            margin-bottom: 10px;
            padding-bottom: 10px;
            border-bottom: 1px solid #f9f9f9;
        }
    }
</style>

<script>
    import ReAuthenticatableIntegration from '@/app/integrations/components/scopes/ReAuthenticatableIntegration'
    import CheckForMissingIntegrations from '@/app/integrations/components/CheckForMissingIntegrations'
    import CheckForMissingScopes from '@/app/integrations/components/scopes/CheckForMissingScopes'
    import FacebookPagesService from '@/services/facebook/FacebookPagesService'
    import Integration from '@/app/onboarding/components/integrations/Integration'
    import PageLineChart from '@/app/meta/components/social/PageLineChart'
    import LandingPage from '@/app/onboarding/components/LandingPage'
    import FacebookPost from '@/app/meta/components/social/FacebookPost'
    import InfiniteLoading from 'vue-infinite-loading'
    import Card from '@/app/shared/components/Card'

    import {mapGetters} from 'vuex'

    import hasIntegration from '@/mixins/integrations/hasIntegration'
    import organization from '@/mixins/customer/organization'

    export default {
        data() {
            return {
                posts: [],
                profile: null,
                demographics: null,
                currentPostKey: null,
                pagination: {},
                limit: 12,
                page: 0
            }
        },

        mixins: [organization, hasIntegration],

        watch: {
            posts() {
                if (!this.posts.length) {
                    this.currentPostKey = null
                    return
                }

                if (this.currentPostKey === null) {
                    this.currentPostKey = 0
                }
            },

            currentPostKey() {
                this.fetchEdgesForCurrentPost()
            },

            identity() {
                if (!this.hasHealthyIntegration('Facebook pages')) {
                    return
                }

                this.loadPosts()
                this.loadProfile()

                // this.loadDemographics()
            }
        },

        computed: {
			...mapGetters('identity', {
			  identity: 'getIdentity'
			}),

			...mapGetters('customer', {
			  customer: 'getCustomer',
			}),

            hasPosts() {
                return this.posts.length > 0
            },

            currentPost() {
                if (this.currentPostKey === null || !this.posts[this.currentPostKey]) {
                    return null
                }

                return this.posts[this.currentPostKey]
            },

            informationHasLoaded() {
                if (!this.hasPosts) {
                    return false
                }

                const information = Object.values(this.posts[this.currentPostKey].information).map(information => information.loaded)

                return !information.includes(false)
            },

            demographicsTotal() {
                if (!this.demographics) {
                    return 0
                }

                const demographics = this.demographics

                return demographics.female + demographics.male
            },

            account() {
                const customer = this.customer
                const oauth2Accounts = customer.oauth2Accounts

                const account = oauth2Accounts.find((account) => {
                    return account.provider === 'facebookPages'
                })

                return account === undefined ? null : account
            },

            scopes() {
                return this.account ? this.account.scopes : []
            }
        },

        mounted() {
            if (!this.hasHealthyIntegration('Facebook pages')) {
                return
            }

            this.loadPosts()
            this.loadProfile()
            // this.loadDemographics()

            eventHub.$on('facebook.social.post.next', this.next)
            eventHub.$on('facebook.social.post.previous', this.previous)
        },

        destroyed() {
            eventHub.$off('facebook.social.post.next', this.next)
            eventHub.$off('facebook.social.post.previous', this.previous)
        },

        methods: {
            fetchEdgesForCurrentPost() {
                if (!this.currentPost) {
                    return
                }

                let post = this.posts[this.currentPostKey]
                const information = post.information

                // Fetching comments
                FacebookPagesService.comments(post.id, {}, (comments) => {
                    const commentsMetrics = information.comments

                    commentsMetrics.total = comments.data.rows.length
                    commentsMetrics.loaded = true
                })

                // Fetch post insights
                const insightsOptions = {
                    parameters: {
                        metric: [
                            'post_impressions_unique',
                            'post_clicks_unique'
                        ]
                    }
                }

                let { shows, interactions, shares, reactions } = information

                FacebookPagesService.postInsights(post.id, insightsOptions, response => {
                    const { rows } = response.data

                    rows.forEach(row => {
                        const values = row.values
                        const first = values[0]

                        switch (row.name) {
                            case 'post_impressions_unique':
                                shows.total = first.value
                                break;

                            case 'post_clicks_unique':
                                interactions.total = first.value
                                break;
                        }
                    })
                })

                const types = ['NONE', 'LIKE', 'LOVE', 'WOW', 'HAHA', 'SAD', 'ANGRY']
                let total = 0
                const promises = []

                types.forEach(type => {
                    promises.push(new Promise((resolve, reject) => {
                        FacebookPagesService.reactions(post.id, {
                            parameters: {
                                type: type
                            }
                        }, response => {
                            const data = response.data
                            const summary = data.summary

                            let lowerCasedType = type.toLowerCase()
                            const reactionCount = summary.total_count

                            total += reactionCount

                            switch (lowerCasedType) {
                                case 'none':
                                    this.$set(reactions, 'total', reactionCount)
                                    this.$set(reactions, 'loaded', true)
                                    break

                                default:
                                    this.$set(reactions.rows, lowerCasedType, reactionCount)
                                    break
                            }

                            resolve()
                        })
                    }, () => {
                        reject()
                    }))
                })

                Promise.all(promises).then(() => {
                    this.$set(reactions, 'total', total)
                })

                this.$set(interactions, 'loaded', true)
                this.$set(shows, 'loaded', true)

                // Share metrics
                this.$set(shares, 'total', post.shares.count || 0)
                this.$set(shares, 'loaded', true)
            },

            useBlueprint(post) {
                // Blueprint, for default values if they are not supplied
                // by facebook graph
                const blueprint = {
                    name: '',
                    message: '',
                    permalink_url: '',
                    shares: {
                        count: 0,
                    },
                    full_picture: '',
                    information: {
                        shows: {
                            icon: 'fa fa-eye',
                            loaded: false,
                            total: 0,
                            tooltip: $t('social.specificPost.information.shows')
                        },
                        interactions: {
                            icon: 'fa fa-mouse-pointer',
                            loaded: false,
                            total: 0,
                            tooltip: $t('social.specificPost.information.clicks')
                        },
                        reactions: {
                            icon: 'fa fa-thumbs-up',
                            total: 0,
                            loaded: false,
                            rows: {
                                like: 0,
                                wow: 0,
                                love: 0,
                                haha: 0,
                                sad: 0,
                                angry: 0,
                            }
                        },
                        shares: {
                            icon: 'fa fa-share',
                            loaded: false,
                            total: 0
                        },
                        comments: {
                            icon: 'fa fa-comments',
                            loaded: false,
                            total: 0
                        }
                    }
                }

                return Object.assign(blueprint, post)
            },

            loadProfile() {
                this.profile = null

                const options = {
                    fields: [
                        'fan_count',
                        'displayed_message_response_time',
                        'general_info',
                        'overall_star_rating',
                        'talking_about_count',
                        'country_page_likes'
                    ]
                }

                FacebookPagesService.page(options, (response) => {
                    this.profile = response.data
                })
            },

            loadDemographics() {
                this.demographics = null

                const options = {
                    parameters: {
                        metric: [
                            'page_fans_gender_age'
                        ]
                    }
                }

                FacebookPagesService.pageInsights(options, response => {
                    const data = response.data
                    const rows = data.rows

                    if (!rows.length) {
                        this.demographics = {}
                        return
                    }

                    const result = rows[0]

                    if (!result || !result.values) {
                        this.demographics = {}
                        return
                    }

                    const metricValues = result.values

                    if (!metricValues.length) {
                        this.demographics = {}
                        return
                    }

                    const lastMetricValue = metricValues[metricValues.length - 1].value

                    const demographics = {
                        female: 0,
                        male: 0,
                        other: 0
                    }

                    Object.keys(lastMetricValue).forEach(key => {
                        const firstLetterOfKey = key.charAt(0).toLowerCase()

                        switch (firstLetterOfKey) {
                            case 'f':
                                demographics.female += lastMetricValue[key]
                                break

                            case 'm':
                                demographics.male += lastMetricValue[key]
                                break

                            case 'u':
                            default:
                                demographics.other += lastMetricValue[key]
                                break
                        }
                    })

                    this.demographics = demographics
                })
            },

            loadNextPage($state) {
                const pagination = this.pagination
                this.loadPosts($state, pagination.next)
            },

            loadPosts($state, pagination) {
                let options = {
                    fields: [
                 //       'name',
                        'message',
                        'permalink_url',
                        'shares',
                        'full_picture',
                        'created_time'
                    ],

                    parameters: {
                        limit: this.limit
                    },
                }

                if (pagination) {
                    options['pagination'] = pagination
                }

                FacebookPagesService.posts(options, posts => {
                    const data = posts.data
                    const rows = data.rows
                    this.pagination = data.pagination

                    if (rows.length < 1) {
                        $state.complete()
                        return
                    }

                    let postsWithBlueprint = rows.map(post => this.useBlueprint(post))

                    this.posts.push(...postsWithBlueprint)
                    this.page++

                    if (this.posts.length < (this.limit * this.page)) {
                        $state.complete()
                        return
                    }

                    $state.loaded()
                })
            },

            setCurrentPost(post) {
                const posts = this.posts

                for(let i = 0; i < posts.length; i++) {
                    if (posts[i].id === post.id) {
                        this.currentPostKey = i
                        return
                    }
                }
            },

            next() {
                if (this.currentPostKey >= this.posts.length - 1) {
                    this.currentPostKey = 0
                    return
                }

                this.currentPostKey++
            },

            previous() {
                if (this.currentPostKey <= 0) {
                    this.currentPostKey = this.posts.length - 1
                    return
                }

                this.currentPostKey--
            }
        },

        components: {
            LandingPage,
            CheckForMissingIntegrations,
            Card,
            FacebookPost,
            PageLineChart,
            InfiniteLoading,
            Integration,
            CheckForMissingScopes,
            ReAuthenticatableIntegration
        }
    }
</script>
