<template>
    <div class="row" v-if="user">
        <card icon="building"
              :headline="$t('social.profile.headline')"
              :description="$t('social.profile.description')">
			<div class="metric-wrapper">
				<div class="meta-row">
					<div class="meta-title">
						<strong>Brugernavn</strong>
					</div>
					<div class="meta-content">
						<a :href="`https://instagram.com/${user.username}`"
						   target="_blank"
						   rel="noreferrer"
						   v-text="user.username"/>
					</div>
				</div>

				<div class="meta-row">
					<div class="meta-title">
						<strong>{{$t('instagram.followers.title')}}</strong>
					</div>
					<div class="meta-content" v-html="$t('instagram.followers.text', {value: user.followers_count})"/>
				</div>

				<div class="meta-row">
					<div class="meta-title">
						<strong>{{ $t('social.posts.title') }}</strong>
					</div>
					<div class="meta-content"
						 v-html="
							$t('social.posts.text', { value: user.media_count })
						"
					/>
				</div>
            </div>
        </card>
    </div>
</template>

<style lang="scss" scoped>
.metric-wrapper {
	display: flex;
	flex-direction: column;
	gap: 14px;
}
</style>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: 'CompanyData',
    computed: mapGetters('instagram', {
      user: 'getUser'
    })
  }
</script>
