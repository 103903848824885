<template>
	<div class="campaigns">
		<div class="table-headlines">
			<div class="main">
				<div v-if="ad !== null" class="title"><span>{{ ad.name }}</span></div>
				<div v-else-if="adSet !== null" class="title"><span>{{ adSet.name }}</span></div>
				<div v-else-if="campaign !== null" class="title"><span>{{ campaign.name }}</span></div>
			</div>

			<div class="controls">
				<status-dropdown v-model="status" />

				<view-dropdown v-model="internalView"
							   :view-requirements="viewRequirements"
				/>

				<div class="search">
					<div class="icon">
						<i class="far fa-search fa-fw" />
					</div>

					<input type="text" v-model="query" />
				</div>
			</div>
		</div>

		<campaigns :network="network"
				   :views="views"
				   :status="status"
				   :view="view"
				   :query="query"
				   :campaign="campaign"
				   :ad-set="adSet"
				   :ad="ad"
				   @campaign="setCampaign"
				   @adSet="setAdSet"
				   @ad="setAd"
		/>
	</div>
</template>

<style lang="scss" scoped>
div.campaigns {
	display: flex;
	flex-direction: column;
	border: 1px solid #eee;
	border-radius: 8px;
	background-color: #fff;

	.main {
		min-width: 320px;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	> div.table-headlines {
		display: flex;
		align-items: center;
		min-height: 60px;
		gap: 20px;
		padding: 15px 25px;
		border-bottom: 1px solid #eee;

		color: #1f1f1f;
		font-size: 16px;
		font-weight: 600;

		.controls {
			display: flex;
			align-items: center;
			gap: 6px;

			margin-left: auto;

			.search {
				position: relative;
				border: 1px solid #ccc;
				border-radius: 6px;
				overflow: hidden;

				> div.icon {
					display: flex;
					align-items: center;
					justify-content: center;
					padding: 0 0 0 8px;
					position: absolute;
					pointer-events: none;
					touch-action: none;
					top: 0;
					bottom: 0;
					left: 0;
					font-size: 16px;
				}

				input {
					height: 36px;
					border: 0;
					outline: 0;
					box-shadow: none;
					padding: 0 14px 0 36px;
					line-height: 100%;
					font-size: 14px;
					font-weight: 400;
				}
			}
		}

		> div.main {
			display: flex;
			align-items: center;
			gap: 8px;

			> div.back {
				color: #000;
				font-size: 16px;
			}

			> div.title {
				> span {
					color: #9f9f9f;
					font-weight: 400;

					&:after {
						color: #9f9f9f;
						display: inline-block;
						padding: 0 7px;
						content: '/';
					}

					&:last-child {
						color: #000;
						font-weight: 500;

						&:after {
							display: none;
						}
					}
				}
			}
		}

		> div.metrics {
			display: flex;
			flex: 1;

			> div.metric {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				gap: 4px;

				flex: 1;

				i {
					font-size: 20px;
				}

				font-size: 12px;
				font-weight: 500;
			}
		}
	}

	> div.table-rows {
		display: flex;
		flex-direction: column;

		> div.table-row {
			display: flex;
			gap: 20px;
			padding: 15px 25px;
			border-bottom: 1px solid #eee;

			&:nth-child(odd) {
				background-color: #fafafa;
			}

			&:hover {
				background-color: #eee;
			}

			.main {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				gap: 6px;

				.name {
					color: #000;
					font-size: 13px;
					font-weight: 500;
				}

				.status-list {
					display: flex;
					align-items: center;
					gap: 6px;

					> div.status {
						display: flex;
						align-items: center;
						gap: 4px;
						line-height: 100%;

						color: #383838;
						font-size: 11px;
						font-weight: 500;
						letter-spacing: -.1px;
						padding: 4px 5px;
						border-radius: 4px;
						background-color: #eee;

						i {
							margin-top: 1px;
						}

						&.success {
							color: #fff;
							background-color: #73af6e;
						}

						&.danger {
							color: #fff;
							background-color: #cc6f6f;
						}
					}
				}
			}

			> div.metrics {
				display: flex;
				flex: 1;

				> div.metric {
					flex: 1;

					display: flex;
					align-items: center;
					justify-content: center;

					> span {
						border: 1px solid #ccc;
						border-radius: 4px;
						padding: 4px 4px;
						line-height: 100%;
						font-size: 13px;
						font-weight: 500;
						background-color: #fff;
					}
				}
			}
		}
	}

	> div.table-pagination {
		padding: 25px;
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 15px;

		> button {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 35px;
			height: 35px;
			border-radius: 50%;
			color: #000;
			font-size: 15px;
			border: 2px solid #ccc;
			background-color: #fff;

			&:disabled {
				opacity: .5;
			}

			&:hover {
				color: #fff;
				border-color: #000;
				background-color: #000;
			}
		}
	}
}

@media(max-width: 750px) {
	div.campaigns {
		> div.table-headlines {
			> div.main {
				display: none;
			}
		}
	}
}
</style>

<script>
import ViewDropdown from '@/app/meta/pages/components/ViewDropdown'
import datepickerMixin from '@/app/datepicker/datepicker.mixin'
import marketingMixin from '@/mixins/marketingMixin'

import StatusDropdown from '@/app/meta/pages/components/campaigns-table/StatusDropdown'
import Campaigns from './Campaigns'

export default {
	mixins: [
		datepickerMixin,
		marketingMixin
	],

	props: {
		network: {
			type: String,
			required: false,
			default: 'meta'
		},

		view: {
			type: String,
			required: true
		},

		views: {
			type: Object,
			required: true
		},

		viewRequirements: {
			type: Object,
			required: true
		}
	},

	data: () => ({
		query: '',

		campaign: null,
		adSet: null,
		ad: null,

		status: 'active',

		limit: 15
	}),

	computed: {
		internalView: {
			get() {
				return this.view
			},
			set(view) {
				this.$emit('view', view)
			}
		}
	},

	watch: {
		campaign() {
			this.query = ''

			this.adSet = null
			this.ad = null
		},

		adSet() {
			this.query = ''
			this.ad = null
		},

		ad() {
			this.query = ''
		}
	},

	methods: {
		setCampaign(campaign) {
			this.campaign = campaign
		},

		setAdSet(adSet) {
			this.adSet = adSet
		},

		setAd(ad) {
			this.ad = ad
		}
	},

	components: {
		Campaigns,
		StatusDropdown,
		ViewDropdown
	}
}
</script>
