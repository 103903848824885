<template>
    <div class="row" v-if="recent">
        <card
            icon="pe-7s-note"
            :headline="$t('instagram.past30days.headline')"
            :description="$t('instagram.past30days.description')"
        >
			<div class="metric-wrapper">
				<div class="meta-row">
					<div class="meta-title">
						<strong>{{
							$t("instagram.followers.title")
						}}</strong>
					</div>
					<div
						class="meta-content"
						v-html="
							$t('instagram.followers.text', {
								value: followers
							})
						"
					/>
				</div>
				<div class="meta-row">
					<div class="meta-title">
						<strong>Side Visninger</strong>
					</div>
					<div class="meta-content">
						Din profil er blevet set
						<span class="g-dynamic-value" v-text="views" /> indenfor de
						sidste 30 dage
					</div>
				</div>
				<div class="meta-row">
					<div class="meta-title">
						<strong>Opslags Visninger</strong>
					</div>
					<div class="meta-content">
						Dine opslag på instagram er blevet set
						<span class="g-dynamic-value" v-text="impressions" />
						indenfor de sidste 30 dage hvor
						<span class="g-dynamic-value" v-text="reach" /> har været
						unikke visninger
					</div>
				</div>
			</div>
        </card>
    </div>
</template>

<style lang="scss" scoped>
.metric-wrapper {
	display: flex;
	flex-direction: column;
	gap: 14px;
}
</style>

<script>
import { mapGetters } from "vuex";

export default {
    name: "RecentData",

    computed: {
        ...mapGetters("instagram", {
            recent: "getRecent"
        }),

        followers() {
            return this.getTotalValue("follower_count");
        },

        views() {
            return this.getTotalValue("profile_views");
        },

        reach() {
            return this.getTotalValue("reach");
        },

        impressions() {
            return this.getTotalValue("impressions");
        }
    },

    methods: {
        getCollection(name) {
            return this.recent.find(obj => {
                return obj.name === name;
            });
        },

        getTotalValue(name) {
            const collection = this.getCollection(name);



            return collection
                ? collection.values
                      .map(row => row.value)
                      .reduce((a, b) => a + b)
                : 0;
        }
    }
};
</script>

<style scoped></style>
