<template>
	<check-for-missing-integrations :integrations="['Facebook ads']">
		<landing-page slot="no-integrations"
					  image="/images/integrations/cta-messages/facebook.png"
					  :headline="$t('meta.onboarding.headline')"
					  :description="$t('meta.onboarding.description')">
			<hr />

			<integration
				name="Facebook ads"
				service="facebook-ads"
				:border="true"
				logo="/images/onboarding/facebook.svg"
				:headline="$t('meta.onboarding.facebook.headline')"
				:description="$t('meta.onboarding.facebook.description')"
			/>

			<hr />
		</landing-page>

		<div class="page">
			<view-selector :view="view"
						   :from="from"
						   :to="to"
						   :view-requirements="viewRequirements"
						   @view="setView"
			/>

			<div>
				<networks :networks="networks"
						  :view="view"
						  :views="views"
				/>
			</div>

			<campaigns-table :network="network"
							 :view="view"
							 :views="views"
							 :view-requirements="viewRequirements"
							 @view="setView"
			/>
		</div>
	</check-for-missing-integrations>
</template>

<style lang="scss" scoped>
.page {
	display: flex;
	flex-direction: column;
	gap: 20px;
}
</style>

<script>
import CampaignsTable from '@/app/meta/pages/components/campaigns-table/CampaignsTable'
import datepickerMixin from '@/app/datepicker/datepicker.mixin'
import marketingMixin from '@/mixins/marketingMixin'

import Networks from '@/app/meta/pages/components/Networks'
import CheckForMissingIntegrations from "@/app/integrations/components/CheckForMissingIntegrations";
import LandingPage from "@/app/onboarding/components/LandingPage";
import Integration from '@/app/onboarding/components/integrations/Integration'

import ViewSelector from '@/app/meta/pages/components/ViewSelector'

export default {
	mixins: [
		datepickerMixin,
		marketingMixin
	],

	data: () => ({
		view: 'default',
		network: 'meta',
		networkData: {},
		networks: {
			meta: {
				icon: 'fab fa-meta fa-fw',
				label: 'Meta'
			},
			facebook: {
				icon: 'fab fa-facebook-square fa-fw',
				label: 'Facebook'
			},
			instagram: {
				icon: 'fab fa-instagram fa-fw',
				label: 'Instagram'
			},
			messenger: {
				icon: 'fab fa-facebook-messenger fa-fw',
				label: 'Messenger'
			},
			audience_network: {
				icon: 'far fa-users fa-fw',
				label: 'Audience Network'
			}
		},

		viewRequirements: {
			efficiency: {
				field: 'reach'
			},
			branding: {
				field: 'impressions'
			},
			video: {
				field: 'video_play_actions',
			},
			ecommerce: {
				field: 'purchase',
				subtype: 'action',
			},
			leads: {
				field: 'lead',
				subtype: 'action',
			},
			contact: {
				field: 'contact_total',
				subtype: 'conversions',
			},
			registrations: {
				field: 'complete_registration',
				subtype: 'action',
			}
		}
	}),

	computed: {
		views() {
			return {
				default: [
					{
						icon: 'far fa-eye',
						field: 'impressions',
						type: 'number',
						label: this.$t(`meta.ads.insights.impressions`)
					},
					{
						icon: 'far fa-users',
						field: 'reach',
						type: 'number',
						label: this.$t(`meta.ads.insights.reach`)
					},
					{
						icon: 'far fa-arrow-right-arrow-left',
						field: 'clicks',
						type: 'number',
						label: this.$t(`meta.ads.insights.clicks`)
					},
					{
						icon: 'far fa-mouse-pointer',
						field: 'inline_link_clicks',
						type: 'number',
						label: this.$t(`meta.ads.insights.outbound_clicks`)
					},
					{
						icon: 'far fa-dollar',
						field: 'cost_per_inline_link_click',
						type: 'currency',
						label: this.$t(`meta.ads.insights.cost_per_inline_link_click`)
					},
					{
						icon: 'far fa-piggy-bank',
						field: 'spend',
						type: 'currency',
						label: this.$t(`meta.ads.insights.spend`)
					}
				],
				efficiency: [
					{
						icon: 'far fa-mouse-pointer',
						field: 'inline_link_clicks',
						type: 'number',
						label: this.$t(`meta.ads.insights.outbound_clicks`)
					},
					{
						icon: 'far fa-dollar',
						field: 'cost_per_inline_link_click',
						type: 'currency',
						label: this.$t(`meta.ads.insights.cost_per_outbound_click`)
					},
					{
						icon: 'far fa-dollar',
						field: 'inline_link_click_ctr',
						type: 'percentage',
						label: this.$t(`meta.ads.insights.outbound_clicks_ctr`)
					},
					{
						icon: 'far fa-wave-pulse',
						field: 'frequency',
						type: 'number',
						label: this.$t(`meta.ads.insights.frequency`)
					},
					{
						icon: 'far fa-users',
						field: 'reach',
						type: 'number',
						label: this.$t(`meta.ads.insights.reach`)
					},
					{
						icon: 'far fa-piggy-bank',
						field: 'spend',
						type: 'currency',
						label: this.$t(`meta.ads.insights.spend`)
					}
				],
				branding: [
					{
						icon: 'far fa-users',
						field: 'reach',
						type: 'number',
						label: this.$t(`meta.ads.insights.reach`)
					},
					{
						icon: 'far fa-eye',
						field: 'impressions',
						type: 'number',
						label: this.$t(`meta.ads.insights.impressions`)
					},
					{
						icon: 'far fa-eyes',
						field: 'cpm',
						type: 'currency',
						label: this.$t(`meta.ads.insights.cpm`)
					},
					{
						icon: 'far fa-wave-pulse',
						field: 'frequency',
						type: 'number',
						label: this.$t(`meta.ads.insights.frequency`)
					},
					{
						icon: 'far fa-play',
						field: 'video_play_actions',
						type: 'number',
						label: this.$t(`meta.ads.insights.video_play_actions`)
					},
					{
						icon: 'far fa-piggy-bank',
						field: 'spend',
						type: 'currency',
						label: this.$t(`meta.ads.insights.spend`)
					}
				],
				video: [
					{
						icon: 'far fa-play',
						field: 'video_play_actions',
						type: 'number',
						label: this.$t(`meta.ads.insights.video_play_actions`)
					},
					{
						icon: 'far fa-users',
						field: 'reach',
						type: 'number',
						label: this.$t(`meta.ads.insights.reach`)
					},
					{
						icon: 'far fa-hundred-points',
						field: 'video_p100_watched_actions',
						type: 'number',
						label: this.$t(`meta.ads.insights.video_p100_watched_actions`)
					},
					{
						icon: 'far fa-percentage',
						type: 'percentage',
						subtype: 'calculation',
						calculation(insights) {
							const fullPlays = Number((((insights.video_p100_watched_actions || [])[0]) || {}).value || 0)
							const plays = Number((((insights.video_play_actions || [])[0]) || {}).value || 0)

							if (! fullPlays || ! plays) {
								return null
							}

							return fullPlays / plays * 100
						},
						label: this.$t(`meta.ads.insights.fullPlayRate`)
					},
					{
						icon: 'far fa-coin',
						type: 'currency',
						subtype: 'calculation',
						calculation(insights) {
							const plays = Number((((insights.video_p100_watched_actions || [])[0]) || {}).value || 0)
							const spend = Number(insights.spend || 0)

							if (! plays || ! spend) {
								return null
							}

							return spend / plays
						},
						label: this.$t(`meta.ads.insights.cost_per_thruplay`)
					},
					{
						icon: 'far fa-piggy-bank',
						field: 'spend',
						type: 'currency',
						label: this.$t(`meta.ads.insights.spend`)
					}
				],
				ecommerce: [
					{
						icon: 'far fa-shopping-cart',
						field: 'add_to_cart',
						type: 'number',
						subtype: 'action',
						label: this.$t(`meta.ads.insights.add_to_cart`)
					},
					{
						icon: 'far fa-check',
						field: 'purchase',
						type: 'number',
						subtype: 'action',
						label: this.$t(`meta.ads.insights.purchase`)
					},
					{
						icon: 'far fa-dollar',
						field: 'omni_purchase',
						type: 'currency',
						subtype: 'action_value',
						label: this.$t(`meta.ads.insights.action_values:omni_purchase`)
					},
					{
						icon: 'far fa-percent',
						type: 'percentage',
						subtype: 'calculation',
						calculation(insights) {
							const actions = insights.actions || []
							const purchases = Number((actions.find(action => action.action_type === 'purchase') || {}).value || 0)
							const clicks = insights.clicks

							if (! purchases || ! clicks) {
								return null
							}

							return purchases / clicks * 100
						},
						label: this.$t(`meta.ads.insights.conversionRate`)
					},
					{
						icon: 'far fa-rotate',
						field: 'website_purchase_roas',
						type: 'number',
						label: this.$t(`meta.ads.insights.website_purchase_roas`)
					},
					{
						icon: 'far fa-piggy-bank',
						field: 'spend',
						type: 'currency',
						label: this.$t(`meta.ads.insights.spend`)
					}
				],
				leads: [
					{
						icon: 'far fa-filter',
						field: 'lead',
						type: 'number',
						subtype: 'action',
						label: this.$t(`meta.ads.insights.leads`)
					},
					{
						icon: 'far fa-coin',
						field: 'lead',
						type: 'currency',
						subtype: 'cost_per_action_type',
						label: this.$t(`meta.ads.insights.pricePerLead`)
					},
					{
						icon: 'far fa-percent',
						type: 'percentage',
						subtype: 'calculation',
						calculation(insights) {
							const actions = insights.actions || []
							const leads = Number((actions.find(action => action.action_type === 'lead') || {}).value || 0)
							const clicks = insights.clicks

							if (! leads || ! clicks) {
								return null
							}

							return leads / clicks * 100
						},
						label: this.$t(`meta.ads.insights.conversionRate`)
					},
					{
						icon: 'far fa-piggy-bank',
						field: 'spend',
						type: 'currency',
						label: this.$t(`meta.ads.insights.spend`)
					}
				],
				contact: [
					{
						icon: 'far fa-envelope',
						field: 'contact_total',
						type: 'number',
						subtype: 'conversions',
						label: this.$t(`meta.ads.insights.contact`)
					},
					{
						icon: 'far fa-coin',
						field: 'contact_total',
						type: 'currency',
						subtype: 'cost_per_conversion',
						label: this.$t(`meta.ads.insights.pricePerContact`)
					},
					{
						icon: 'far fa-percentage',
						type: 'percentage',
						subtype: 'calculation',
						calculation(insights) {
							const conversions = insights.conversions || []
							const contacts = Number((conversions.find(action => action.action_type === 'contact_total') || {}).value || 0)
							const clicks = insights.clicks

							if (! contacts || ! clicks) {
								return null
							}

							return contacts / clicks * 100
						},
						label: this.$t(`meta.ads.insights.conversionRate`)
					},
					{
						icon: 'far fa-piggy-bank',
						field: 'spend',
						type: 'currency',
						label: this.$t(`meta.ads.insights.spend`)
					}
				],
				registrations: [
					{
						icon: 'far fa-user-plus',
						field: 'complete_registration',
						type: 'number',
						subtype: 'action',
						label: this.$t(`meta.ads.insights.complete_registration`)
					},
					{
						icon: 'far fa-coin',
						field: 'complete_registration',
						type: 'currency',
						subtype: 'cost_per_action_type',
						label: this.$t(`meta.ads.insights.pricePerRegistration`)
					},
					{
						icon: 'far fa-percentage',
						type: 'percentage',
						subtype: 'calculation',
						calculation(insights) {
							const actions = insights.actions || []
							const registrations = Number((actions.find(action => action.action_type === 'complete_registration') || {}).value || 0)
							const clicks = insights.clicks

							if (! registrations || ! clicks) {
								return null
							}

							return registrations / clicks * 100
						},
						label: this.$t(`meta.ads.insights.conversionRate`)
					},
					{
						icon: 'far fa-piggy-bank',
						field: 'spend',
						type: 'currency',
						label: this.$t(`meta.ads.insights.spend`)
					}
				]
			}
		}
	},

	mounted() {
		this.pushFilters()

		this.$store.commit("datepicker/setShowComparisonToggleButton", true)
	},

	destroyed() {
		this.$store.commit("datepicker/setShowComparisonToggleButton", false)
	},

	methods: {
		setView(view) {
			this.view = view
		},

		pushFilters() {
			eventHub.$emit("topbar.filterGroups.push", {
				title: "Netværk",
				slug: "search-network",
				metrics: Object.keys(this.networks).map(key => ({
					label: this.networks[key].label,
					slug: key,
					selected: key === 'meta'
				}))
			});

			eventHub.$on(
				"topbar.filterGroups.search-network.metricsChanged",
				(payload) => {
					Object.keys(payload.metrics).forEach((metricKey) => {
						const metricValue = payload.metrics[metricKey];

						if (!metricValue) {
							return;
						}

						this.network = metricKey
					});
				}
			);
		}
	},

	components: {
		Integration,
		LandingPage,
		CheckForMissingIntegrations,
		Networks,
		CampaignsTable,
		ViewSelector
	}
}
</script>
