<template>
    <div style="position: sticky; top: 30px;">
        <card
            ref="post"
            class="instagram-post"
            icon="newspaper"
            :headline="$t('instagram.specificPost.headline')"
            :description="$t('instagram.specificPost.description')"
        >
            <logo-loader :height="300" v-if="isLoading" />

            <article class="post" v-else>
                <header>
                    <div class="video" @click="play" v-if="isVideo">
                        <div class="overlay"><fa icon="play" /></div>
                        <video ref="video" :poster="post.thumbnail_url">
                            <source :src="post.media_url" type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    </div>

                    <figure v-else>
                        <img :src="post.media_url" alt="" />
                    </figure>
                </header>

                <section>
                    <time v-text="displayDate" :datetime="post.timestamp" />
                    <p class="caption">
                        {{ post.caption | substr(0, 120) }}...
                    </p>

                    <a
                        :href="post.permalink"
                        target="_blank"
                        class="btn btn-primary btn-sm"
                        v-text="$t('instagram.specificPost.goTo')"
                    />
                </section>
                <footer :class="{ 'has-video-views': hasVideo }">
                    <div :title="$t('instagram.specificPost.comments_count')">
                        <fa icon="comment" />
                        <span>{{ post.comments_count }}</span>
                    </div>
                    <div :title="$t('instagram.specificPost.like_count')">
                        <fa icon="thumbs-up" />
                        <span>{{ post.like_count }}</span>
                    </div>
                    <div
                        v-tooltip="insights.video_views.description"
                        v-if="hasVideo"
                    >
                        <fa icon="play" />
                        <span>{{ insights.video_views.value }}</span>
                    </div>
                    <div v-if="!! insights.impressions" v-tooltip="insights.impressions.description">
                        <fa icon="eye" />
                        <span>{{ insights.impressions.value }}</span>
                    </div>
                    <div v-tooltip="insights.reach.description">
                        <fa icon="users" />
                        <span>{{ insights.reach.value }}</span>
                    </div>
                    <div v-tooltip="insights.saved.description">
                        <fa icon="save" />
                        <span>{{ insights.saved.value }}</span>
                    </div>
                </footer>
            </article>
        </card>
    </div>
</template>

<style scoped lang="scss">
.post {
    header {
        border: 1px solid #f0f0f0;
        border-bottom: 0;
        border-radius: 2px 2px 0 0;
        overflow: hidden;
    }

    img,
    video {
        width: 100%;
        margin: 0 0 -6px !important;
    }

    video {
        cursor: pointer;
    }

    .video {
        position: relative;
        .overlay {
            position: absolute;
            z-index: 200000;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            background: rgba(0, 0, 0, 0.3);
            color: #ffffff;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 30px;
            cursor: pointer;
            opacity: 0;
            transition: opacity ease-in-out 300ms;
        }

        &:hover .overlay {
            opacity: 1;
        }
    }

    section {
        padding: 20px;
        border: 1px solid #f0f0f0;
        border-top: 0;
    }

    time {
        font-size: 85%;
        display: block;
        margin-bottom: 10px;
        color: #888;
    }

    footer {
        background: #fafafa;
        border-bottom: 1px solid #f0f0f0;
        border-left: 1px solid #f0f0f0;
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        &.has-video-views {
            grid-template-columns: repeat(6, 1fr);
        }

        div {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            height: 64px;
            font-size: 12px;
            border-right: 1px solid #f0f0f0;
            cursor: default;

            span {
                display: block;
                margin-top: 5px;
                font-size: 14px;
            }
        }
    }
}
</style>

<script>
import moment from "moment";
import { mapActions } from "vuex";

export default {
    name: "ViewPost",
    props: {
        post: {
            type: Object,
            required: true,
            default: {
                permalink: null,
                id: 0,
                comments_count: 0,
                like_count: 0,
                caption: "",
                media_type: "",
                media_url: "",
                thumbnail_url: ""
            }
        },
        token: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            isLoading: true,
            insights: {}
        };
    },
    watch: {
        post() {
            this.load();
        }
    },
    computed: {
        isVideo() {
            return this.post.media_type === "VIDEO";
        },

        isReel() {
            return this.post.media_product_type === "REELS";
        },

        isCarousel() {
            return this.post.media_type === "CAROUSEL_ALBUM";
        },
        displayDate() {
            return moment(this.post.timestamp).format("DD.MM.YYYY");
        },
        hasVideo() {
            return (
                this.insights.video_views && this.insights.video_views.value > 0
            );
        }
    },
    mounted() {
        this.load();
    },
    methods: {
        ...mapActions("instagram", {
            fetch: "fetchPostInsights"
        }),
        play() {
            this.$refs.video.play();
        },
        insightsParams() {
            // carousel_album_engagement: Total number of likes and IG Comments on the album IG Media object.
            // carousel_album_impressions: Total number of times the album IG Media object has been seen.
            // carousel_album_reach: Total number of unique Instagram accounts that have seen the album IG Media object.
            // carousel_album_saved: Total number of unique Instagram accounts that have saved the album IG Media object.
            // carousel_album_video_views: (Video IG Media objects only) Always returns 0. Use carousel_album_reach and carousel_album_impressions instead.
            if (this.isCarousel) {
                return [
                    "carousel_album_engagement",
                    "carousel_album_impressions",
                    "carousel_album_reach",
                    "carousel_album_saved",
                    "carousel_album_video_views"
                ].join(",");
            }

            // engagement: Total number of likes and IG Comments on the IG Media object.
            // impressions: Total number of times the IG Media object has been seen.
            // reach: Total number of unique Instagram accounts that have seen the IG Media object.
            // saved: Total number of unique Instagram accounts that have saved the IG Media object.
            // video_views: (Video IG Media objects only) Total number of times the video has been seen. Returns 0 for videos in an album IG Media object.

            const params = ["total_interactions", "reach", "saved"];

            if (this.isVideo && ! this.isReel) {
                params.push("video_views");
            }
            if (! this.isReel) {
                params.push("impressions");
            }

            return params.join(",");
        },
        async load() {
            this.isLoading = true;

            const response = await this.fetch({
                id: this.post.id,
                token: this.token,
                metric: this.insightsParams()
            });

            this.insights = {};

            await response.forEach(row => {
                row.value = 0;

                row.values.forEach(({ value }) => {
                    row.value += value;
                });

                delete row.values;

                this.insights[row.name.replace("carousel_album_", "")] = row;
            });

            this.isLoading = false;
        }
    }
};
</script>
