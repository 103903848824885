<template>
    <article class="instagram-graph">
        <card icon="pe-7s-graph3"
              :headline="$t('instagram.historical.headline')"
              :description="$t('instagram.historical.description')">
            <div class="chart-container" v-if="!isLoading">
                <highcharts :options="chartOptions"/>
            </div>
            <loader v-else/>
        </card>
    </article>
</template>

<style lang="scss">
    .instagram-graph {
        .chart-container {
            width: 100%;
        }

        .card .content > div {
            display: flex;
            align-items: flex-end;
        }
    }
</style>

<script>
  import { mapGetters } from 'vuex'
  import moment from 'moment'

  export default {
    computed: {
      ...mapGetters('instagram', {
        graph: 'getRecent'
      }),

      isLoading () {
        return !this.graph
      },

      categories () {
        const categories = []

        const from = moment().subtract(29, 'days')

        for (let i = 0; i < 30; i++) {
          categories.push(from.format($t('i18n.dateFormat')))
          from.add(1, 'day')
        }

        return categories
      },
      series () {
        if (!this.graph) {
          return []
        }

        return this.graph.map(collection => {
          const rtrn = {
            name: collection.title,
            description: collection.description,
            period: collection.period
          }

          rtrn.data = collection.values.map(row => row.value)
          rtrn.total = rtrn.data.reduce((a, b) => a + b)
          rtrn.visible = collection.name !== 'reach' && collection.name !== 'impressions'
          rtrn.marker = {
            symbol: 'circle',
            radius: 3,
            lineWidth: 2,
            lineColor: '#fff'
          }

          return rtrn
        }).filter(collection => collection.total > 0)
      },
      chartOptions () {
        if (!this.graph) {
          return {}
        }

        return {
          legend: {
            itemStyle: {
              'fontFamily': '"Roboto","Helvetica Neue",sans-serif',
              'fontWeight': 400
            },
            symbolRadius: 0,
            labelFormatter: function () {
              return this.name
            }
          },
          tooltip: {
            shared: true,
            formatter: function () {
              let text = ''

              this.points.forEach(function (point) {
                text += point.series.name + ': '
                text += point.y
                text += '<br />'
              })

              return text
            }
          },

          plotOptions: {
            series: {
              stacking: 'normal'
            }
          },

          xAxis: {
            categories: this.categories
          },

          yAxis: {
            enabled: false,
            title: {
              text: null
            },
            allowDecimals: false
          },

          chart: {
            type: 'area',
            stacking: 'normal',
            zoomType: 'x'
          },
          title: {
            text: null
          },
          series: this.series
        }
      }
    }
  }
</script>
