<template>
	<main id="reporting-instagram" class="container-fluid">
		<div slot="no-integrations" v-if="!checkIsHealthyIntegration(['Facebook pages']) || !hasInstagram">
			<landing-page
				image="/images/integrations/cta-messages/facebook-social.png"
				:headline="$t('instagram.noIntegration.headline')"
				:description="$t('instagram.noIntegration.description')"
			>
				<integration
					name="Facebook pages"
					service="facebook-pages"
					:border="true"
					:logos="['/images/onboarding/facebook.svg','/images/onboarding/instagram.svg']"
					:headline="$t('social.onboarding.headline')"
					:description="$t('social.onboarding.description')"
				/>
			</landing-page>
		</div>
		<logo-loader v-else-if="!isLoaded"/>
		<section v-else>
			<section class="row row-md-flex">
				<area-chart class="col-lg-8 col-md-6 col-sm-12 col-md-flex"/>
				<aside class="col-lg-4 col-md-6 col-sm-12 col-md-flex">
					<section class="col-xs-12">
						<company-data/>
						<recent-data/>
					</section>
				</aside>
			</section>

			<section class="row">
				<audience class="col-lg-12 audience"/>
			</section>

			<media-posts />
		</section>
	</main>
</template>

<script>
import LandingPage from '@/app/onboarding/components/LandingPage'
import Integration from '@/app/onboarding/components/integrations/Integration'
import hasIntegration from '@/mixins/integrations/hasIntegration'
import Instagram from '@/app/meta/instagram.store'

import AreaChart from '../components/AreaChart'
import Audience from '../components/Audience'
import CompanyData from '../components/CompanyData'
import RecentData from '../components/RecentData'
import MediaPosts from '../components/MediaPosts'

import {mapActions, mapGetters} from 'vuex'

export default {
	name: 'instagramSocial',
	mixins: [Instagram, hasIntegration],

	data() {
		return {
			isLoaded: false,
			notLoaded: false
		}
	},

	computed: {
		...mapGetters('customer', {
			customer: 'getCustomer'
		}),
		...mapGetters('instagram', {
			hasInstagram: 'hasInstagram',
		})
	},

	mounted() {
		this.load()
	},

	beforeDestroy() {
		this.reset()
	},

	methods: {
		...mapActions('instagram', ['checkForIntegration', 'fetchData', 'reset']),

		async load() {
			this.isLoaded = false;
			const hasInstagram = await this.checkForIntegration(this.customer.id);

			if (hasInstagram) {
				await this.fetchData('fetchData');
				this.isLoaded = true;
			}
		},

		checkIsHealthyIntegration(names) {
			return this.hasHealthyIntegration(names)
		}
	},

	components: {
		MediaPosts,
		RecentData,
		CompanyData,
		AreaChart,
		Audience,
		Integration,
		LandingPage
	}
}
</script>

<style scoped lang="scss">
.meta-row {
	margin-bottom: 15px;

	&:last-child {
		margin: 0;
	}

	@supports (display: grid) {
		@media screen and (min-width: 1199px) {
			display: grid;
			grid-template-columns: 120px 1fr;
			grid-gap: 10px;
		}
	}
}
</style>
