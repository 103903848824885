<template>
    <article>
        <loader v-if="isLoading"/>
        <div class="cities-container" v-else>
            <p><strong>Top land og byer</strong></p>
            <div class="progress" style="height: 30px;margin-bottom: 0">
                <div class="progress-bar progress-bar-primary"
                     :style="{width: `${topCountry.percent}%`, paddingTop: '6px'}"
                     style="padding-top: 6px;">
                    <span>
                        <strong>{{topCountry.countryCode | country}}</strong>
                    </span>
                </div>
            </div>
            <p class="help-block">{{topCountry.value}} Besøgende ud af {{countriesTotal}}</p>

            <table class="table">
                <thead>
                <tr>
                    <th>By</th>
                    <th/>
                </tr>
                </thead>
                <tbody>
                    <tr v-for="(row, index) in sortedCities" :key="index">
                        <td>{{row.city}}</td>
                        <td>{{row.value}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </article>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    props: {
      isLoading: {
        type: Boolean,
        required: true
      },
      cities: {
        required: true
      },
      countries: {
        required: true
      }
    },
    computed: {
      sortedCities () {
        return this.cities
          .sort((a, b) => a.value < b.value ? 1 : -1)
          .slice(0, 3)
      },

      countriesSorted () {
        return this.countries.sort((a, b) => a.value < b.value ? 1 : -1)
      },

      countriesTotal () {
        if (this.countries.length === 0) return 0

        return this.countries
          .map(row => row.value)
          .reduce((a, b) => a + b)
      },

      topCountry () {
        const topCountry = { ...this.countriesSorted[0] }

        topCountry.percent = Math.round((topCountry.value / this.countriesTotal) * 100)

        return topCountry
      },
    }
  }
</script>
