<template>
	<div class="table-list">
		<div class="table-headlines">
			<div class="main">
				<div @click="setAd(null)" class="back"><i class="fas fa-chevron-left fa-fw" /></div>

				<div class="title">
					<span @click="setCampaign(null)">{{ $t('meta.ads.campaigns') }}</span>
					<span @click="setAdSet(null)">{{ $t('meta.ads.adSets') }}</span>
					<span @click="setAd(null)">{{ $t('meta.ads.ads') }}</span>
				</div>
			</div>
		</div>

		<div class="previews">
			<div v-if="previews !== null"
				 v-for="(preview, index) in previews"
				 :key="`preview-${index}`"
				 class="preview"
				 v-html="preview.body"
			/>
		</div>
	</div>
</template>

<style lang="scss" scoped>
div.table-list {
	display: flex;
	flex-direction: column;
	border: 1px solid #eee;
	border-radius: 8px;
	background-color: #fff;

	.main {
		width: 320px;
	}

	.previews {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 25px;
		padding: 25px;
	}

	> div.table-headlines {
		display: flex;
		align-items: center;
		min-height: 60px;
		gap: 20px;
		padding: 15px 25px;
		border-bottom: 1px solid #eee;

		color: #1f1f1f;
		font-size: 16px;
		font-weight: 600;

		.controls {
			display: flex;
			align-items: center;
			gap: 6px;

			margin-left: auto;

			.search {
				position: relative;
				border: 1px solid #ccc;
				border-radius: 6px;
				overflow: hidden;

				> div.icon {
					display: flex;
					align-items: center;
					justify-content: center;
					padding: 0 0 0 8px;
					position: absolute;
					pointer-events: none;
					touch-action: none;
					top: 0;
					bottom: 0;
					left: 0;
					font-size: 16px;
				}

				input {
					height: 36px;
					border: 0;
					outline: 0;
					box-shadow: none;
					padding: 0 14px 0 36px;
					line-height: 100%;
					font-size: 14px;
					font-weight: 400;
				}
			}
		}

		> div.main {
			display: flex;
			align-items: center;
			gap: 8px;

			> div.back {
				color: #000;
				font-size: 16px;
				min-width: 30px;
				min-height: 30px;
				width: 30px;
				height: 30px;
				display: flex;
				align-items: center;
				justify-content: center;
				border-radius: 50%;
				cursor: pointer;
				user-select: none;

				&:hover {
					background-color: #eee;
				}
			}

			> div.title {
				> span {
					color: #9f9f9f;
					font-weight: 400;

					&:after {
						color: #9f9f9f;
						display: inline-block;
						padding: 0 7px;
						content: '/';
					}

					&:hover {
						cursor: pointer;
						user-select: none;
						color: #000;
						text-decoration: underline;
					}

					&:last-child {
						color: #000;
						font-weight: 500;

						&:after {
							display: none;
						}
					}
				}
			}
		}
	}
}
</style>

<script>
import FacebookService from '@/services/_app/meta/FacebookService'

export default {
	props: {
		ad: {
			type: Object,
			required: false,
			default: null
		}
	},

	data: () => ({
		previews: null
	}),

	mounted() {
		this.loadPreviews()
	},

	methods: {
		loadPreviews() {
			FacebookService.previews(this.ad.id).then(response => {
				this.previews = response.previews || []
			})
		},

		setCampaign(campaign) {
			this.$emit('campaign', campaign)
		},

		setAdSet(adSet) {
			this.$emit('adSet', adSet)
		},

		setAd(ad) {
			this.$emit('ad', ad)
		}
	}
}
</script>
