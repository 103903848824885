<template>
    <div class="container-fluid facebook-post">
        <div class="row" v-if="post">
            <div class="post-wrapper">
                <div class="post-image" :style="{ 'background-image': 'url(' + postImageURL + ')' }"></div>

                <small class="post-date">{{post.created_time | moment('DD.MM.YYYY')}}</small>

                <div class="post-text">
                    <h4 v-if="post.name" class="post-text__headline">{{post.name}}</h4>

                    <p class="post-text__description">{{post.message | substr(0, 120)}} ...</p>

                    <a :href="post.permalink_url" target="_blank">
                        <button type="button" class="btn btn-primary btn-sm">{{$t('social.specificPost.seeOnFacebook')}}</button>
                    </a>
                </div>

                <div class="control-circle next" @click="next" :style="organizationBackgroundColor">
                    <i class="fa fa-arrow-right"></i>
                </div>

                <div class="control-circle previous" @click="previous" :style="organizationBackgroundColor">
                    <i class="fa fa-arrow-left"></i>
                </div>
            </div>

            <div v-if="informationHasLoaded" class="post-values position">
                <div :key="key"
                    v-for="(info, key) in post.information"
                    class="value"
                    :class="{ 'mobile-active': mobileActive }"
                    v-tooltip="info.tooltip">
                    <i :class="info.icon"></i><br>{{info.total}}

                    <ul v-if="key === 'reactions'" class="reaction-hover">
                        <li :key="type" v-for="(reactions, type) in info.rows">
                            <span class="reaction__icon" :style="{ backgroundImage: 'url(/images/facebook/btn-' + type + '.png)' }"></span>

                            <span class="reaction__value">{{reactions}}</span>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="text-center" v-else>
                <span class="fa fa-circle-o-notch fa-fw fa-spin"/>
            </div>

            <div class="post--preview">
                <div class="post--ghost-image"></div>
                <div class="post--ghost-text"></div>
                <div class="post--ghost-text"></div>
                <div class="post--ghost-text"></div>
                <div class="post--ghost-text"></div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    .post-wrapper {
        border: 1px solid #f0f0f0;
        padding: 20px;
        position: relative;
        .post-image {
            background: #f9f9f9;
            width: calc(100% + 40px);
            height: 250px;
            margin-bottom: 20px;
            margin-top: -20px;
            margin-left: -20px;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
        }
        .post-text {
            .post-text__headline {
                font-weight: 500;
            }
        }
    }

    .post-date {
        display: block;
        margin-bottom: 10px;
        color: #888;
    }

    .post-values {
        z-index: 5;
        position: relative;
        transform: translateY(-60px);
        transition: all 0.7s ease;
        &.position {
            transform: translateY(0) !important;
        }
        .value {
            float: left;
            text-align: center;
            padding: 12px;
            background: #fafafa;
            width: 20%;
            &.mobile-active {
                &:first-child {
                    background: #282828;
                    color: #fff;
                }
                .reaction-hover {
                    opacity: 1;
                    transform: translateY(0);
                }
            }
            &:hover {
                background: #282828;
                color: #fff;
                .reaction-hover {
                    opacity: 1;
                    transform: translateY(0);
                }
            }
            .reaction-hover {
                width: 100%;
                padding: 0px 20px 10px 20px;
                background: #282828;
                color: #fff;
                position: absolute;
                top: 64px;
                left: 0;
                opacity: 0;
                list-style-type: none;
                transition: all 0.4s ease;
                transform: translateY(5px);
                .reaction__icon {
                    width: 100%;
                    display: block;
                    padding: 0 10px;
                    background-size: 30%;
                    background-repeat: no-repeat;
                    background-position: center center;
                    &:before {
                        content: '';
                        display: block;
                        padding-top: 100%;
                    }
                }
                li {
                    float: left;
                    width: 16.66667%;
                }
            }
            &:not(:first-child) {
                border-left: 1px solid #f2f2f2;
            }
        }
    }

    .control-circle {
        width: 160px;
        height: 40px;
        position: absolute;
        top: 210px;
        right: 0;
        cursor: pointer;
        background: #FFA534;
        color: #fff;
        &.previous {
            width: 40px;
            height: 40px;
            left: 0;
        }

        &.next {
            width: 40px;
            height: 40px;
            right: 0;
        }

        i {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    @media screen and (max-width: 1670px) {
        .post-wrapper {
            .post-image {
                width: calc(100% + 40px);
            }
        }
        .reaction__icon {
            width: 20px;
            height: 20px;
            background-size: contain !important;
            display: inline-block;
            float: left;
            margin-top: 10px !important;
            margin-right: 0 !important;
        }
    }

    @media screen and (max-width: 768px) {
        .facebook-post {
            padding-bottom: 60px;
        }
        .reaction-hover {
            text-align: center;
        }
        .reaction__value {
            width: 100%;
            display: block;
        }
    }
</style>

<script>
    export default {
        props: {
            post: Object
        },

        data() {
            return {
                windowWidth: window.innerWidth
            }
        },

        mounted() {
            window.addEventListener('resize', () => {
                this.windowWidth = window.innerWidth
            })
        },

        destroyed() {
            window.removeEventListener('resize', () => {
                this.windowWidth = window.innerWidth
            })
        },

        computed: {
            mobileActive() {
                return this.windowWidth < 768
            },

            organizationBackgroundColor() {
                return {backgroundColor:$org('colors.standard.primary.hex')}
            },

            postImageURL() {
                const post = this.post

                if (!post) {
                    return
                }

                return post.full_picture
            },

            informationHasLoaded() {
                if (!this.post) {
                    return
                }

                const information = Object.values(this.post.information)
                    .map(information => information.loaded)

                return !information.includes(false)
            }
        },

        methods: {
            next() {
                eventHub.$emit('facebook.social.post.next')
            },

            previous() {
                eventHub.$emit('facebook.social.post.previous')
            }
        }
    }
</script>
