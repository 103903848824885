<template>
    <div>
        <slot name="no-scopes" v-if="!hasScopes" />
        <slot v-else />
    </div>
</template>

<script>
    export default {
        props: {
            requiredScopes: {
                type: Array,
                required: true,
            },

            scopes: {
                type: Array,
                required: true,
            },
        },

        computed: {
            hasScopes() {
                const scopes = this.scopes
                const requiredScopes = this.requiredScopes

                for(let i = 0; i < requiredScopes.length; i++) {
                    if (!scopes.includes(requiredScopes[i])) {
                        return false
                    }
                }

                return true
            }
        },
    }
</script>