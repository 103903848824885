const BaseService = require('@/services/BaseService')

const namespace = 'facebook-pages'

const page = (options, cb, errorCb) => {
    BaseService.get(namespace + '/page', options, cb, errorCb)
}

const post = (id, options, cb, errorCb) => {
    BaseService.get(namespace + '/post/' + id, options, cb, errorCb)
}

const posts = (options, cb, errorCb) => {
    BaseService.get(namespace + '/page/posts', options, cb, errorCb)
}

const reactions = (id, options, cb, errorCb) => {
    BaseService.get(namespace + '/post/' + id + '/reactions', options, cb, errorCb)
}

const comments = (id, options, cb, errorCb) => {
    BaseService.get(namespace + '/post/' + id + '/comments', options, cb, errorCb)
}

const pageInsights = (options, cb, errorCb) => {
    BaseService.get(namespace + '/page/insights', options, cb, errorCb)
}

const postInsights = (id, options, cb, errorCb) => {
    BaseService.get(namespace + '/post/' + id + '/insights', options, cb, errorCb)
}

const account = (options, cb, errorCb) => {
    BaseService.get(namespace + '/account/', options, cb, errorCb)
}

export default {
    page,
    post,
    posts,
    reactions,
    comments,
    pageInsights,
    postInsights,
    account
}
