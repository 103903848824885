<template>
    <article>
        <slot/>
        <div class="gender-container">
            <figure class="male text-center">
                <img alt="" src="@/assets/images/analytics/audience/male.png" :style="{height: maleHeight + 'px'}"/>
                <div class="text-muted">
                    <span v-if="isLoading" class="mock">0%</span>
                    <span v-else>{{malePercent | percent}}</span>
                </div>
            </figure>

            <figure class="female text-center">
                <img alt="" src="@/assets/images/analytics/audience/female.png" :style="{height: femaleHeight + 'px'}"/>
                <div class="text-muted">
                    <span v-if="isLoading" class="mock">0%</span>
                    <span v-else>{{femalePercent | percent}}</span>
                </div>
            </figure>

            <figure class="unknown text-center">
                <img alt="" src="@/assets/images/analytics/audience/question-mark.png" :style="{height: unknownHeight + 'px'}"/>
                <div class="text-muted">
                    <span v-if="isLoading" class="mock">0%</span>
                    <span v-else>{{unknownPercent | percent}}</span>
                </div>
            </figure>
        </div>
    </article>
</template>

<style scoped lang="scss">
    .gender-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;

        figure {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            flex-direction: column;
            width: 100px;
            height: 150px;

            img {
                height: 100px;
                transition: all .5s ease;
            }

            & > div {
                border-top: 1px solid #dedede;
                margin-top: 14px;
                padding: 15px 15px 0;
            }
        }

    }
</style>

<script>

  export default {
    data () {
      return {
        maxHeight: 100
      }
    },

    props: {
      isLoading: {
        type: Boolean,
        required: true
      },

      genders: {
        type: Object,
        required: true
      },

      total: {
        type: Number,
        required: true
      }
    },

    computed: {
      male () {
        return this.genders.M
      },

      female () {
        return this.genders.F
      },

      unknown () {
        return this.genders.U
      },

	  max() {
		  return Math.max(this.male || 0, this.female || 0, this.unknown || 0)
	  },

      malePercent () {
        if (this.total === 0) {
          return 0
        }

        return this.male / this.total
      },

      femalePercent () {
        if (this.total === 0) {
          return 0
        }

        return this.female / this.total
      },

	  unknownPercent () {
        if (this.total === 0) {
          return 0
        }

        return this.unknown / this.total
      },

      maleHeight () {
        const mostMale = ((this.malePercent - 0.5) > 0)

        if (mostMale || this.isLoading) {
          return this.maxHeight
        }

        return (this.maxHeight / this.max) * this.male
      },

      femaleHeight () {
        const mostFemale = ((this.femalePercent - 0.5) > 0)

        if (mostFemale || this.isLoading) {
          return this.maxHeight
        }

        return (this.maxHeight / this.max) * this.female
      },

      unknownHeight () {
        const mostUnknown = ((this.unknownPercent - 0.5) > 0)

        if (mostUnknown || this.isLoading) {
          return this.maxHeight
        }

        return (this.maxHeight / this.max) * this.unknown
      }
    }
  }
</script>
