<template>
	<div v-if="adSet === null"
		 class="table-list">
		<div class="table-headlines">
			<div class="main">
				<div @click="setCampaign(null)" class="back"><i class="fas fa-chevron-left fa-fw" /></div>

				<div class="title">
					<span @click="setCampaign(null)">{{ $t('meta.ads.campaigns') }}</span>
					<span>{{ $t('meta.ads.adSets') }}</span>
				</div>
			</div>

			<div class="metrics">
				<div v-for="item in views[view]" class="metric">
					<i class="fa-fw" :class="item.icon" />
					<span>{{ item.label }}</span>
				</div>
			</div>
		</div>

		<div class="table-rows">
			<div v-if="items !== null"
				 v-for="item in filteredItems"
				 @click="setAdSet(item)"
				 :key="`ad-set-${item.id}`"
				 class="table-row">
				<div class="main">
					<div class="name">{{ item.name }}</div>

					<div class="status-list">
						<div v-if="item.status === 'ACTIVE'" class="status success">
							<i class="fas fa-check fa-fw" />
							<span>{{ $t('meta.ads.statuses.active') }}</span>
						</div>

						<div v-else class="status danger">
							<i class="fas fa-remove fa-fw" />
							<span>{{ $t('meta.ads.statuses.inactive') }}</span>
						</div>

						<div v-if="!! item.start_time || !! item.stop_time"
							 class="status">
							<i class="fas fa-clock fa-fw" />
							<span>
								<template v-if="!! item.start_time">{{ formatDate(item.start_time) }}</template>
								<template v-if="!! item.stop_time">- {{ formatDate(item.stop_time) }}</template>
							</span>
						</div>
					</div>
				</div>

				<div class="metrics">
					<div v-for="viewItem in views[view]" class="metric">
						<div class="metric-label">
							<i class="fa-fw" :class="viewItem.icon" />
							<span>{{ viewItem.label }}</span>
						</div>

						<template v-if="hasValue(item.id, viewItem.field)">
							<span v-if="formatValue(item.id, viewItem.field, viewItem.type, viewItem.subtype, viewItem) !== null">{{ formatValue(item.id, viewItem.field, viewItem.type, viewItem.subtype, viewItem) }}</span>
							<span v-else>-</span>
						</template>
						<span v-else><i class="far fa-spinner-third fa-spin fa-fw" /></span>
					</div>
				</div>
			</div>

			<div v-if="items === null"
				 v-for="n in 10"
				 :key="`placeholder-${n}`"
				 class="table-row">
				<div class="mock main">
					<div class="name">Name here</div>
				</div>

				<div class="mock metrics">
					<div v-for="item in views[view]" class="metric">
						<div class="metric-label">
							<i class="fa-fw" :class="view.icon" />
							<span>{{ view.label }}</span>
						</div>

						<span>XYZ</span>
					</div>
				</div>
			</div>
		</div>
	</div>

	<ads v-else
	     :network="network"
	 	 :views="views"
	 	 :status="status"
	 	 :view="view"
	 	 :query="query"
	 	 :campaign="campaign"
	 	 :ad-set="adSet"
	 	 :ad="ad"
	 	 @campaign="setCampaign"
	 	 @adSet="setAdSet"
	 	 @ad="setAd"
	/>
</template>

<style lang="scss" scoped>
div.table-list {
	display: flex;
	flex-direction: column;
	border: 1px solid #eee;
	border-radius: 8px;
	background-color: #fff;

	.main {
		width: 320px;
	}

	> div.table-headlines {
		display: flex;
		align-items: center;
		min-height: 60px;
		gap: 20px;
		padding: 15px 25px;
		border-bottom: 1px solid #eee;

		color: #1f1f1f;
		font-size: 16px;
		font-weight: 600;

		.controls {
			display: flex;
			align-items: center;
			gap: 6px;

			margin-left: auto;

			.search {
				position: relative;
				border: 1px solid #ccc;
				border-radius: 6px;
				overflow: hidden;

				> div.icon {
					display: flex;
					align-items: center;
					justify-content: center;
					padding: 0 0 0 8px;
					position: absolute;
					pointer-events: none;
					touch-action: none;
					top: 0;
					bottom: 0;
					left: 0;
					font-size: 16px;
				}

				input {
					height: 36px;
					border: 0;
					outline: 0;
					box-shadow: none;
					padding: 0 14px 0 36px;
					line-height: 100%;
					font-size: 14px;
					font-weight: 400;
				}
			}
		}

		> div.main {
			display: flex;
			align-items: center;
			gap: 8px;

			> div.back {
				color: #000;
				font-size: 16px;
				min-width: 30px;
				min-height: 30px;
				width: 30px;
				height: 30px;
				display: flex;
				align-items: center;
				justify-content: center;
				border-radius: 50%;
				cursor: pointer;
				user-select: none;

				&:hover {
					cursor: pointer;
					user-select: none;
					background-color: #eee;
				}
			}

			> div.title {
				> span {
					color: #9f9f9f;
					font-weight: 400;

					&:after {
						color: #9f9f9f;
						display: inline-block;
						padding: 0 7px;
						content: '/';
					}

					&:hover {
						cursor: pointer;
						user-select: none;
						color: #000;
						text-decoration: underline;
					}

					&:last-child {
						color: #000;
						font-weight: 500;

						&:after {
							display: none;
						}
					}
				}
			}
		}

		> div.metrics {
			display: flex;
			flex: 1;

			> div.metric {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				gap: 4px;

				flex: 1;

				i {
					font-size: 20px;
				}

				font-size: 12px;
				font-weight: 500;
			}
		}
	}

	> div.table-rows {
		display: flex;
		flex-direction: column;

		> div.table-row {
			display: flex;
			gap: 20px;
			padding: 15px 25px;
			border-bottom: 1px solid #eee;

			&:nth-child(odd) {
				background-color: #fafafa;
			}

			&:hover {
				cursor: pointer;
				user-select: none;
				background-color: #eee;
			}

			.main {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				gap: 6px;

				.name {
					color: #000;
					font-size: 13px;
					font-weight: 500;
				}

				.status-list {
					display: flex;
					align-items: center;
					gap: 6px;

					> div.status {
						display: flex;
						align-items: center;
						gap: 4px;
						line-height: 100%;

						color: #383838;
						font-size: 11px;
						font-weight: 500;
						letter-spacing: -.1px;
						padding: 4px 5px;
						border-radius: 4px;
						background-color: #eee;

						i {
							margin-top: 1px;
						}

						&.success {
							color: #fff;
							background-color: #73af6e;
						}

						&.danger {
							color: #fff;
							background-color: #cc6f6f;
						}
					}
				}
			}

			> div.metrics {
				display: flex;
				flex: 1;

				> div.metric {
					flex: 1;

					display: flex;
					align-items: center;
					justify-content: center;

					.metric-label {
						display: none;
					}

					> span {
						border: 1px solid #ccc;
						border-radius: 4px;
						padding: 4px 4px;
						line-height: 100%;
						font-size: 13px;
						font-weight: 500;
						background-color: #fff;
					}
				}
			}
		}
	}
}

@media(max-width: 750px) {
	div.table-list {
		.main {
			width: 100%;
		}

		> div.table-headlines {
			> div.metrics {
				display: none;
			}
		}

		> div.table-rows {
			> div.table-row {
				display: flex;
				flex-direction: column;

				> div.main {
					flex: 0;
				}

				> div.metrics {
					flex: 0;

					display: flex;
					flex-direction: column;
					align-items: flex-start;

					> div.metric {
						display: flex;
						width: 100%;
						justify-content: space-between;
						border-bottom: 1px solid #eee;
						padding: 10px 0;

						.metric-label {
							display: flex;
							align-items: center;
							gap: 6px;

							font-weight: 500;

							i {
								color: #274fd2;
							}
						}

						&:last-child {
							border-bottom: 0;
						}
					}
				}
			}
		}
	}
}
</style>

<script>
import FacebookService from '@/services/_app/meta/FacebookService'
import datepickerMixin from '@/app/datepicker/datepicker.mixin'
import marketingMixin from '@/mixins/marketingMixin'

import moment from 'moment'
import Ads from './Ads'

export default {
	components: {Ads},

	mixins: [
		datepickerMixin,
		marketingMixin
	],

	props: {
		network: {
			type: String,
			required: false,
			default: 'meta'
		},

		views: {
			type: Object,
			required: true
		},

		status: {
			type: String,
			required: true
		},

		view: {
			type: String,
			required: true
		},

		campaign: {
			type: Object,
			required: false,
			default: null
		},

		adSet: {
			type: Object,
			required: false,
			default: null
		},

		ad: {
			type: Object,
			required: false,
			default: null
		},

		query: {
			type: String,
			required: false,
			default: ''
		}
	},

	data: () => ({
		items: null,

		token: null,
		insights: {}
	}),

	computed: {
		filteredItems() {
			const query = String(this.query || '').toLowerCase()

			if (query.length === 0) {
				return this.items
			}

			return this.items.filter(item => String(item.name || '').toLowerCase().includes(query))
		}
	},

	watch: {
		status() {
			this.reset()
			this.onLoad()
		},

		network() {
			this.insights = {}
			this.onLoadInsights()
		},

		items() {
			this.insights = {}
			this.onLoadInsights()
		}
	},

	mounted() {
		this.onLoad()
	},

	methods: {
		reset() {
			this.items = []
			this.token = null
			this.direction = null
			this.after = null
			this.before = null
			this.insights = {}
		},

		onLoad() {
			FacebookService.adSets(this.campaign.id, {
				fields: [
					'name',
					'start_time',
					'stop_time',
					'status',
				]
			}).then(response => {
				this.items = response.adSets
			})
		},

		onLoadInsights() {
			const items = this.items || []

			items.forEach(item => {
				FacebookService.adSetInsightsTotal(this.campaign.id, item.id, {
					from: this.from.format('YYYY-MM-DD'),
					to: this.to.format('YYYY-MM-DD'),

					network: this.network,

					fields: [
						'action_values',
						'outbound_clicks',
						'outbound_clicks_ctr',
						'inline_link_clicks',
						'inline_link_click_ctr',
						'cost_per_inline_link_click',
						'cost_per_outbound_click',
						'clicks',
						'reach',
						'impressions',
						'spend',
						'website_purchase_roas',
						'frequency',
						'cpm',
						'video_play_actions',
						'video_p100_watched_actions',
						'cost_per_thruplay',
						'action_values',
						'actions',
						'account_currency',
						'conversions',
						'cost_per_conversion',
						'cost_per_action_type'
					]
				}).then(response => {
					this.$set(this.insights, item.id, response.insights[0] || {})
				})
			})
		},

		hasValue(id, field) {
			return !! this.insights[id]
		},

		formatValue(id, field, type, subtype, view) {
			if (! this.hasValue(id, field)) {
				return null
			}

			let value = null

			if (subtype === 'calculation') {
				value = !! view.calculation
					? view.calculation(JSON.parse(JSON.stringify(this.insights[id])))
					: null
			} else if (subtype === 'action') {
				const actions = this.insights[id].actions || []
				const match = actions.find(action => action.action_type === field)

				value = !! match ? match.value : null
			} else if (subtype === 'action_value') {
				const actions = this.insights[id].action_values || []
				const match = actions.find(action => action.action_type === field)

				value = !! match ? match.value : null
			} else if (subtype === 'conversions') {
				const conversions = this.insights[id].conversions || []
				const match = conversions.find(conversion => conversion.action_type === field)

				value = !! match ? match.value : null
			} else if (subtype === 'cost_per_conversion') {
				const conversions = this.insights[id].cost_per_conversion || []
				const match = conversions.find(conversion => conversion.action_type === field)

				value = !! match ? match.value : null
			} else if (subtype === 'cost_per_action_type') {
				const conversions = this.insights[id].cost_per_action_type || []
				const match = conversions.find(conversion => conversion.action_type === field)

				value = !! match ? match.value : null
			} else {
				if (typeof this.insights[id][field] === 'object') {
					value = Array.isArray(this.insights[id][field])
						? this.insights[id][field][0].value
						: this.insights[id][field].value
				} else {
					value = this.insights[id][field]
				}
			}

			if (! value) {
				return null
			}

			switch (type) {
				case 'currency':
					return this.marketingFormat(
						Number(value),
						type,
						{ currency: this.insights[id].account_currency }
					)

				default:
					return this.marketingFormat(
						value,
						type
					)
			}
		},

		setCampaign(campaign) {
			this.$emit('campaign', campaign)
		},

		setAdSet(adSet) {
			this.$emit('adSet', adSet)
		},

		setAd(ad) {
			this.$emit('ad', ad)
		},

		formatDate(date) {
			return moment(date).format('ll')
		}
	}
}
</script>
