<template>
    <div class="integration" :class="[{styled: styled}, styling]" :id="elementId">
        <div :class="{'border': border}">
            <img :src="logo" v-if="styling === 'landscape'">

            <div class="text-container">
                <h5>
                    <img :src="logo" v-if="styling === 'portrait'">

                    {{headline}}
                </h5>

                <strong v-text="$t('onboarding.scopes.needPermissions')"/>
                <br>
                <br>

                <span class="text-muted" v-if="description" v-text="description"/>


                <div class="clearfix"/>

                <div class="controls">
                    <template v-if="service.length !== 0">
                        <div class="connect" v-if="!isConnected">
                            <div v-if="!accounts.length">
                                <template v-if="!isLoading">
                                    <div>
                                        <button class="btn btn-primary btn-md btn-fill" :class="{'btn-lg': (styling == 'portrait')}" @click="connect">
                                            {{$t('onboarding.scopes.giveAccess')}}
                                        </button>
                                    </div>
                                </template>

                                <template v-else>
                                    <div class="loader"></div>

                                    <template v-if="isSlow">
                                        <br/>

                                        <small>
                                            <span class="fa fa-circle-o-notch fa-spin fa-fw"/>&nbsp;
                                            {{$t('onboarding.stalling')}}
                                        </small>
                                    </template>
                                </template>
                            </div>

                            <div v-else>
                                <multiselect class="choose-account"
                                             :placeholder="$t('multiSelect.selectYourAccount')"
                                             v-model="account"
                                             :options="formattedAccounts"
                                             track-by="label"
                                             label="label"
                                             :close-on-select="true"
                                             :show-labels="false"
                                             :selectLabel="$t('multiSelect.selectLabel')">
                                    <p slot="noResult">{{ $t('multiSelect.noResultsFound') }}</p>
                                </multiselect>
                            </div>
                        </div>

                        <template v-else>
                            <div class="connected text-muted" :class="{'connected-lg': (styling == 'portrait')}">
                                <span class="fa fa-check fa-fw"/>
                                {{$t('onboarding.connected')}}
                            </div>

                            <div class="remove-container">
                                <a @click="remove()" class="text-muted" v-if="!isRemoving">{{$t('onboarding.remove',
                                    {source: headline})}}</a>
                                <i class="fa fa-circle-o-notch fa-spin" v-else/>
                            </div>
                        </template>
                    </template>

                    <slot></slot>
                </div>
            </div>

            <div class="clearfix"></div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    @import '~@/assets/scss/_custom.scss';

    .integration {
        position: relative;
        color: initial;

        &.styled {
            > div {
                border-radius: 4px;
                background-color: #fff;
                padding: 28px 30px;

                &.border {
                    border: 1px solid #dedede;
                }
            }
        }

        .loader {
            overflow: hidden;
            position: relative;
            width: 100%;
            height: 4px;
            background-color: #ececec;
            border-radius: 8px;
            margin-top: 10px;

            &:after {
                content: " ";
                position: absolute;
                width: 98%;
                left: 0;
                top: 0;
                height: 100%;
                background-color: #ffca54;
                animation-name: loader-integration;
                animation-duration: 10s;
            }
        }

        img {
            width: 30px;
        }

        h5 {
            font-size: 18px;
            padding: 0;
            margin: 0;
            line-height: 1.2;
        }

        .text-container {
        }

        .controls {
            .connect {
            }

            .connected {
                border: 1px solid #ccc;
                padding: 8px 16px;
                border-radius: 4px;

                .fa-check {
                    color: #00c49f;
                }
            }
        }

        &.landscape {
            img {
                margin-right: 15px;
                margin-top: 3px;
                float: left;
            }

            h5 {
                margin-bottom: 10px;
            }

            .text-container {
                width: calc(100% - 50px);
                float: left;
            }

            .connected {
                margin-top: 15px;
                float: left;
            }

            .remove-container {
                float: left;
                margin-top: 22px;
                margin-left: 15px;
            }

            .btn {
                display: block;
                float: left;
            }

            .help-link {
                display: block;
                float: left;
                margin: 22px 0 0 15px;
            }

            .choose-account {
                margin-top: 15px;
            }
        }

        &.portrait {
            text-align: center;
            position: relative;
            height: 100%;

            > div {
                padding: 20px 15px 135px;
            }

            img {
                margin-top: -10px;
                margin-right: 5px;
            }

            h5 {
                margin-top: 15px;
                margin-bottom: 20px;
                font-weight: bold;
            }

            .controls {
                position: absolute;
                margin-top: 15px;
                width: calc(100% - 30px);
                bottom: 15px;

                .btn {
                    margin-left: auto;
                    margin-right: auto;
                    margin-bottom: 22px;
                }

                .connect {
                    min-height: 85px;
                }

                .connected {
                    display: inline-block;
                    margin-top: 15px;

                    &.connected-lg {
                        font-size: 15px;
                        padding: 0 18px;
                        line-height: 43px;
                    }
                }
            }

            .remove-container {
                margin-top: 18px;
            }
        }

        .btn {
            margin-top: 15px;
        }

        @keyframes loader-integration {
            0% {
                width: 0%;
            }
            10% {
                width: 70%;
            }
            40% {
                width: 80%;
            }
            50% {
                width: 90%;
            }
            100% {
                width: 98%;
            }
        }
    }
</style>

<script>
  const OAuth2Service = require('@/services/oauth2/OAuth2Service')
  const IntegrationService = require('@/services/integrations/IntegrationService')
  import MixpanelService from '@/services/mixpanel/MixpanelService'

  import { mapActions, mapGetters } from 'vuex'
  import hasIntegration from '@/mixins/integrations/hasIntegration'

  const VueScrollTo = require('vue-scrollto')

  export default {
    mixins: [hasIntegration],

    data () {
      return {
        isLoading: false,
        isSlow: false,
        isConnected: false,
        accounts: [],
        account: null,
        highlight: false,
        timeout: null
      }
    },

    props: {
      logo: {
        type: String
      },

      headline: {
        type: String
      },

      description: {
        type: String,
        required: false
      },

      service: {
        type: String,
        default: ''
      },

      name: {
        type: String
      },

      styled: {
        type: Boolean,
        default: true
      },

      styling: {
        type: String,
        default: 'landscape'
      },

      border: {
        type: Boolean,
        default: false
      },

      help: {
        type: String,
        default: 'page-change'
      },

      longHelpText: {
        type: Boolean,
        default: true
      }
    },

    watch: {
      account () {
        this.selectAccount()
      },

      highlight () {
        if (this.highlight) {
          this.$highlighter.addElement(this.$el)

          return
        }

        this.$highlighter.removeElement(this.$el)
      },

      isLoading () {
        if (this.timeout) {
          clearTimeout(this.timeout)
        }

        if (!this.isLoading) {
          return
        }

        this.isSlow = false

        this.timeout = setTimeout(() => {
          this.isSlow = true
        }, 8500)
      }
    },

    mounted () {
      this.validateCode()

      this.isConnected = false
    },

    computed: {
      ...mapGetters('identity', {
        identity: 'getIdentity'
      }),
      ...mapGetters('customer', {
        customer: 'getCustomer'
      }),

      integrations () {
        if (!this.customer) {
          return []
        }

        return this.customer.integrations
      },

      fullPath () {
        return window.location.origin + this.$router.currentRoute.path
      },

      code () {
        const currentRoute = this.$router.currentRoute
        const query = currentRoute.query

        return query.code
      },

      state () {
        const currentRoute = this.$router.currentRoute
        const query = currentRoute.query

        return query.state
      },

      formattedAccounts () {
        return this.accounts.map(function (account) {
          return {
            label: account.name,
            value: account.id
          }
        })
      },

      elementId () {
        return 'id-' + this.service
      }
    },

    methods: {
      ...mapActions('identity', {
        reloadIdentity: 'loadIdentity'
      }),

      scrollToElement () {
        VueScrollTo.scrollTo(this.$el, 400, { offset: -60 })
      },

      validateCode () {
        if (this.state !== this.service || !this.code || this.isConnected) {
          return
        }

        this.isLoading = true
        this.highlight = true

        this.scrollToElement()

        // Remove code
        OAuth2Service.callback(this.service, this.fullPath, this.code, async (response) => {
          const data = response.data

          if (data.skipAccounts) {
            await this.reloadIdentity()

            this.$highlighter.removeElement(this.$el)

            this.isConnected = true
            this.isLoading = false
            this.highlight = false

            MixpanelService.track('Integration - Create', { 'Integration type': this.name })

            return
          }

          this.loadAccounts(data)
        }, () => {
          this.$swal({
            type: 'error',
            text: $t('onboarding.unsuccessfulConnectionTo', { source: this.headline })
          })

          this.isLoading = false
          this.highlight = false
        })
      },

      loadAccounts (data) {
        this.highlight = true

        let options = {}

        if (data.id) {
          options = {
            id: data.id
          }
        }

        OAuth2Service.accounts(this.service, options, (response) => {
          this.isLoading = false

          MixpanelService.track('Integration - Choose account', { 'Integration type': this.name })

          this.accounts = response.data
        }, () => {
          this.isLoading = false
          this.highlight = false

          this.$swal({
            type: 'error',
            text: $t('onboarding.unsuccessfulConnectionTo', { source: this.headline })
          })
        })
      },

      connect () {
        this.remove(() => {
          this.isLoading = true
          this.highlight = true

          MixpanelService.track('Integration - Intention', { 'Integration type': this.name }, () => {
            OAuth2Service.url(this.service, this.fullPath, (response) => {
              const data = response.data

              window.location.href = data.url
            }, () => {
              this.isLoading = false
              this.highlight = false
            })
          })
        })
      },

      selectAccount () {
        this.isLoading = true
        this.highlight = true

        if (!this.account) {
          return
        }

        OAuth2Service.integrate(this.service, this.account.label, this.account.value, async () => {
          await this.reloadIdentity()

          this.$highlighter.removeElement(this.$el)

          this.isConnected = true
          this.isLoading = false
          this.highlight = false

          MixpanelService.track('Integration - Create', { 'Integration type': this.name })

        }, () => {
          this.$swal({
            type: 'error',
            text: $t('onboarding.unsuccessfulConnectionTo', { source: this.headline })
          })

          this.isLoading = false
          this.highlight = false
        })
      },

      remove (cb) {
        const integrations = this.integrations.filter((integration) => {
          const service = integration.service

          return (service && service.name === this.name)
        })

        if (!integrations.length) {
          return
        }

        const integration = integrations[0]

        IntegrationService.remove('integrations', integration.id, () => {
          this.accounts = []
          this.account = null

          cb()
        }, () => {
          cb()
        })
      }
    }
  }
</script>
