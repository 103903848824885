<template>
	<div class="views">
		<div v-for="key in Object.keys(views)"
			 :key="`view-${key}`"
			 :class="{ active: view === key }"
			 @click="select(key)"
			 class="view">
			<div class="icon">
				<i :class="views[key].icon" />
			</div>

			<span class="view-label" v-text="views[key].label" />
		</div>
	</div>
</template>

<style lang="scss" scoped>
.views {
	display: flex;
	flex-wrap: wrap;
	gap: 10px;

	> div.view {
		display: flex;
		flex-direction: column;
		gap: 2px;
		color: #000;
		padding: 12px 20px;
		background-color: #eee;
		border-radius: 3px;

		cursor: pointer;
		user-select: none;

		&:hover {
			background-color: #ccc;
		}

		&.active {
			color: #fff;
			background-color: #f09ad6;
		}

		> div.icon {
			font-size: 18px;
		}

		> span.view-label {
			font-size: 12px;
			font-weight: 500;
		}
	}
}
</style>

<script>
import FacebookService from "@/services/_app/meta/FacebookService";

export default {
	props: {
		view: {
		},

		viewRequirements: {},

		from: {},
		to: {}
	},

	data: () => ({
		values: {}
	}),

	computed: {
		views() {
			const views = {
				default: {
					icon: 'far fa-list fa-fw',
					label: this.$t('meta.ads.views.default')
				},

				efficiency: {
					icon: 'far fa-mouse-pointer fa-fw',
					label: this.$t('meta.ads.views.efficiency')
				},

				branding: {
					icon: 'far fa-certificate fa-fw',
					label: this.$t('meta.ads.views.branding')
				},

				video: {
					icon: 'far fa-video fa-fw',
					label: this.$t('meta.ads.views.video')
				},

				ecommerce: {
					icon: 'far fa-shopping-cart fa-fw',
					label: this.$t('meta.ads.views.ecommerce')
				},

				leads: {
					icon: 'far fa-filter fa-fw',
					label: this.$t('meta.ads.views.leads')
				},

				contact: {
					icon: 'far fa-envelope fa-fw',
					label: this.$t('meta.ads.views.contact')
				},

				registrations: {
					icon: 'far fa-user-plus fa-fw',
					label: this.$t('meta.ads.views.registrations')
				}
			}

			const requirements = this.viewRequirements

			return Object.keys(views).reduce(
				(carry, key) => {
					let value = null

					if (!! requirements[key] && this.view !== key) {
						if (requirements[key].subtype === 'action') {

							const actions = this.values.actions || []
							const match = actions.find(action => action.action_type === requirements[key].field)

							value = !! match ? match.value : null
						} else if (requirements[key].subtype === 'conversions') {
							const conversions = this.values.conversions || []
							const match = conversions.find(conversion => conversion.action_type === requirements[key].field)

							value = !! match ? match.value : null
						} else {
							if (typeof this.values[requirements[key].field] === 'object') {
								value = Array.isArray(this.values[requirements[key].field])
									? this.values[requirements[key].field][0].value
									: this.values[requirements[key].field].value
							} else {
								value = this.values[requirements[key].field]
							}
						}

						if (! value) {
							return carry
						}
					}

					return {
						...carry,
						[key]: views[key]
					}
				},
				{}
			)
		}
	},

	watch: {
		from() {
			this.onLoad()
		}
	},

	mounted() {
		this.onLoad()
	},

	methods: {
		onLoad() {
			return new Promise((resolve) => {
				FacebookService.accountInsightsTotal({
					from: this.from.format('YYYY-MM-DD'),
					to: this.to.format('YYYY-MM-DD'),

					fields: [
						'action_values',
						'outbound_clicks',
						'outbound_clicks_ctr',
						'inline_link_clicks',
						'inline_link_click_ctr',
						'cost_per_inline_link_click',
						'cost_per_outbound_click',
						'clicks',
						'reach',
						'impressions',
						'spend',
						'website_purchase_roas',
						'frequency',
						'cpm',
						'video_play_actions',
						'video_p100_watched_actions',
						'cost_per_thruplay',
						'action_values',
						'actions',
						'account_currency',
						'conversions',
						'cost_per_conversion',
						'cost_per_action_type'
					]
				}).then(response => {
					this.values = response.insights[0] || {}

					resolve()
				})
			})
		},

		select(view) {
			this.$emit('view', view)
		}
	}
}
</script>
