<template>
	<div v-click-outside="onClose" class="dropdown-wrapper">
		<div @click="expanded = ! expanded"
			 class="button">
			<template v-if="!! view">
				<i :class="view.icon" />
				<span>{{ view.label }}</span>
			</template>

			<i class="far fa-chevron-down fa-fw" />
		</div>

		<div v-if="expanded"
			 class="views">
			<div v-for="key in Object.keys(views)"
				 :key="`view-${key}`"
				 @click="onChange(key)"
				 class="view">
				<i :class="views[key].icon" />
				<span>{{ views[key].label }}</span>
			</div>
		</div>
	</div>
</template>

<script>
import ClickOutside from 'vue-click-outside'


export default {
	directives: {
		ClickOutside
	},

	props: {
		value: {}
	},

	data: () => ({
		expanded: false
	}),

	computed: {
		views() {
			return {
				active: {
					icon: 'far fa-check fa-fw',
					label: this.$t('meta.ads.statuses.active')
				},

				inactive: {
					icon: 'far fa-remove fa-fw',
					label: this.$t('meta.ads.statuses.inactive')
				}
			}
		},

		view() {
			return this.views[this.internalValue]
		},

		internalValue: {
			get() {
				return this.value
			},

			set(value) {
				this.$emit('input', value)
			}
		}
	},

	methods: {
		onChange(value) {
			this.internalValue = value
			this.onClose()
		},

		onClose() {
			this.expanded = false
		}
	}
}
</script>

<style lang="scss" scoped>
.dropdown-wrapper {
	position: relative;

	> div.button {
		cursor: pointer;
		user-select: none;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		gap: 10px;
		padding: 0 10px;
		color: #000;
		font-size: 14px;
		font-weight: 500;
		border: 1px solid #ccc;
		border-radius: 6px;
		height: 36px;
	}

	> div.views {
		position: absolute;
		top: 100%;
		right: 0;
		min-width: 200px;
		border: 1px solid #ccc;
		border-radius: 6px;
		background-color: #fff;
		margin-top: 10px;
		z-index: 99;

		> div.view {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			gap: 10px;
			color: #000;
			font-size: 14px;
			font-weight: 400;
			padding: 10px 15px;
			border-bottom: 1px solid #eee;

			&:hover {
				cursor: pointer;
				user-select: none;
				background-color: #eee;
			}
		}
	}
}
</style>
