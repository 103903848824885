<template>
    <section>
        <card icon="pe-7s-users"
              :headline="$t('instagram.audience.headline')"
              description="Køns-, alders- og landsfordelingen for din virksomhed profils følgere">
            <section class="audience-group">
                <gender :is-loading="isLoading"
						:total="insightsGender[0].total_value.breakdowns[0].results.reduce((carry, item) => carry + item.value, 0)"
						:genders="insightsGender[0].total_value.breakdowns[0].results.reduce((carry, item) => ({ ...carry, [item.dimension_values[0]]: item.value }), {})"
						class="gender">
                    <strong>{{$t('visitors.audience.gender')}}</strong>
                </gender>

                <age :is-loading="isLoading"
					 :total="insightsAge[0].total_value.breakdowns[0].results.reduce((carry, item) => carry + item.value, 0)"
					 :ages="insightsAge[0].total_value.breakdowns[0].results.reduce((carry, item) => ([ ...carry, { ageRange: item.dimension_values[0], value: item.value} ]), [])"
					 class="age">
                    <strong>{{$t('visitors.audience.age')}}</strong>
                </age>

                <cities :is-loading="isLoading"
						:cities="insightsCity[0].total_value.breakdowns[0].results.reduce((carry, item) => [...carry, {city: item.dimension_values[0], value: item.value}], [])"
						:countries="insightsCountry[0].total_value.breakdowns[0].results.reduce((carry, item) => [...carry, {countryCode: item.dimension_values[0], value: item.value}], [])"
						class="city">
                    <strong>Byer &amp; Lande</strong>
                </cities>
            </section>
        </card>
    </section>
</template>

<style scoped lang="scss">
    .gender, .age, .city {
        position: relative;

        > strong {
            position: absolute;
        }
    }

    @supports (display: grid) {
        .audience-group {
            min-height: 250px;
            display: grid;
            grid-template-areas: "gender" "hr-t" "age" "hr-b" "cities";
            grid-template-columns: 1fr;

            .gender {
                grid-area: gender;
            }

            .age {
                grid-area: age;
            }

            .city {
                grid-area: cities;
            }

            &::before,
            &::after {
                content: "";
                display: block;
                background: #ddd;
                height: 100%;
                width: 100%;
            }

            &::before {
                grid-area: hr-t
            }

            &::after {
                grid-area: hr-b
            }

            @media screen and (min-width: 800px) {
                grid-template-areas: "gender vr-l age vr-r cities";
                grid-template-columns: 1fr 1px 1fr 1px 1fr;
                grid-gap: 25px;

                &::before {
                    grid-area: vr-l
                }
                &::after {
                    grid-area: vr-r
                }
            }
        }
    }
</style>
<script>
  import Gender from './audience/Gender'
  import Age from './audience/Age'
  import Cities from './audience/Cities'
  import { mapGetters } from 'vuex'

  export default {
    computed: {
      ...mapGetters('instagram', {
        insightsAge: 'getInsightsAge',
        insightsGender: 'getInsightsGender',
        insightsCountry: 'getInsightsCountry',
        insightsCity: 'getInsightsCity',
      }),

      isLoading () {
        return ! this.insightsAge
      }
    },

    components: {
      Gender,
      Age,
      Cities
    }
  }
</script>
