<template>
    <div>
        <logo-loader v-if="!hasLoaded"/>
        <div class="bar-chart">
            <div class="chart"></div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    .bar-chart {
        padding-top: 50px;
    }
</style>

<script>
  import FacebookPagesService from '@/services/facebook/FacebookPagesService'
  import datepickerMixin from '@/app/datepicker/datepicker.mixin'

  import HighCharts from 'highcharts'
  import moment from 'moment'

  export default {
    mixins: [datepickerMixin],
    data () {
      return {
        chart: null,
        hasLoaded: false,
        metrics: []
      }
    },

    computed: {
      labels () {
        const labels = []

        let from = moment(this.from)
          .startOf(this.interval)

        while (from < this.to) {
          const to = new moment(from)

          to.add(1, this.interval)

          const label = this.getLabel(from)

          if (labels.indexOf(label) !== -1) {
            return
          }

          labels.push(label)

          // Iterate
          from = to
        }

        return labels
      },

      intervals () {
        let intervals = []

        let from = moment(this.from)
          .startOf(this.interval)
          .hours(0)
          .minutes(0)
          .seconds(0)

        while (from < this.to) {
          const to = new moment(from)
          to.add(1, this.interval)

          intervals.push({ from: from, to: to })

          // repeat
          from = to
        }

        return intervals
      }
    },

    mounted () {
      this.load()
    },

    methods: {
      load () {
        this.hasLoaded = false

        this.metrics = []

        const options = {
          parameters: {
            since: this.from.format('YYYY-MM-DD'),
            until: this.to.format('YYYY-MM-DD'),
            period: 'day',

            metric: [
              'page_impressions_paid_unique',
              'page_impressions_organic_unique_v2',
              'page_fan_adds_unique',
              'page_total_actions',
              'page_actions_post_reactions_total'
            ]
          }
        }

        FacebookPagesService.pageInsights(options, response => {
          const { rows } = response.data
          this.metrics = rows

          this.createSeries()
        })
      },

      createSeries () {
        const intervals = this.intervals

        let series = Object.values(this.metrics).map(metric => {
          const name = metric.name

          let dataSerie = []

          intervals.forEach(interval => {
            const metricValues = metric.values.filter(metricValue => {
              const date = moment(metricValue.end_time)

              return date.isBetween(interval.from, interval.to)
            })

            let total = 0

            metricValues.forEach(metricValue => {
              total += this.calculateMetricValue(metricValue)
            })

            dataSerie.push(total)
          })

          return {
            name: metric.name,
            data: dataSerie,
            visible: !(name.toLowerCase().includes('page_impressions')),
            marker: {
              symbol: 'circle',
              radius: 3,
              lineWidth: 2,
              lineColor: '#fff'
            }
          }
        })

        this.render(series)
      },

      calculateMetricValue (metricValue) {
        const value = metricValue.value

        if (value instanceof Array) {
          return 0
        }

        if (!(value instanceof Object)) {
          return value
        }

        return Object.values(value).reduce((a, b) => a + b)
      },

      render (series) {
        if (this.chart) {
          this.chart.destroy()
        }

        const el = this.$el.querySelector('.chart')
        const height = 350

        this.hasLoaded = true

        let component = this

        this.chart = new HighCharts.Chart(el, {
          credits: false,
          chart: {
            type: 'area',
            stacking: 'normal',
            zoomType: 'x',
            height: height
          },
          title: {
            text: null
          },
          legend: {
            itemStyle: {
              'fontFamily': '"Roboto","Helvetica Neue",sans-serif',
              'fontWeight': 400
            },
            symbolRadius: 0,

            labelFormatter: function () {
              return component.translate(this.name)
            }
          },
          tooltip: {
            shared: true,
            formatter: function () {
              let total = 0
              let text = '<strong>' + this.x + '</strong><br />'

              this.points.forEach(function (point) {
                text += component.translate(point.series.name) + ': '
                text += point.y
                text += '<br />'
                total += point.y
              })

              return text
            }
          },

          plotOptions: {
            series: {
              stacking: 'normal'
            }
          },

          xAxis: {
            categories: this.labels
          },

          yAxis: {
            enabled: false,
            title: {
              text: null
            },
            allowDecimals: false
          },

          series: series.sort((a, b) => {
            if (a.name.includes('page_impressions')) {
              return -1
            }

            return 1
          })
        })
      },

      translate (name) {
        const key = `social.chart.${name}`
        const translation = $t(key)

        return key !== translation ? translation : name
      },
    }
  }
</script>
